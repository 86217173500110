import React, { Component } from 'react';
// import { createBrowserHistory } from "history";
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Async } from 'react-select';
import Dropzone from 'react-dropzone'
// import Script from 'react-load-script';
// import Cookies from 'js-cookie';
// import Auth from '../../auth';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import 'font-awesome/css/font-awesome.css';

// const history = createBrowserHistory();

const editorConfiguration = {
	removePlugins: [ "ImageUpload" ]
};

function utf8_for_xml(inputStr) {
  return inputStr.replace(/[^\x20-\xFF\x85\xA0-\uD7FF\uE000-\uFDCF\uFDE0-\uFFFD]/gm, '');
}

var API = '';

if (window.location.hostname === 'localhost') {
  API = 'http://' + window.location.hostname + ':3001/api';
} else {
  API = 'https://' + window.location.hostname + '/api';
}

function titleCase(str) {
   var splitStr = str.toLowerCase().split(' ');
   for (var i = 0; i < splitStr.length; i++) {
       // You do not need to check if i is larger than splitStr length, as your for does that for you
       // Assign it back to the array
       splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
   }
   // Directly return the joined string
   return splitStr.join(' '); 
}

var getAllDocuments = (input) => {
  var sid = localStorage.getItem('sid')

  return axios.post(API + `/all-documents`, {
		data: {
			sid: sid
		}
    })
  .then((response) => {
    var documentList = []

    Object.values(response.data.files.recordsets[0]).forEach(function(trait) {
      documentList.push({ value: trait.DocumentId, label: trait.ActualDocumentName })
    });

    return documentList
  }).then((json) => {
    return { options: json }
  })
}

class EditorComponent extends Component {
  constructor (props) {
    super(props);

    this.state = {
      reactIsErroring: false,
   	  model: '',
   	  key: '',
   	  editing: '',
   	  loading: '',
   	  allContent: '',
      currentSessions: '',
      selectedDocument: '',
      documentId: this.props.selectedDocumentId
    };

    this.handleFieldChange = this.handleFieldChange.bind(this)
    this.handleModelChange = this.handleModelChange.bind(this)
    this.componentDidUpdate = this.componentDidUpdate.bind(this)
    this.removeSection = this.removeSection.bind(this)
  }

  handleModelChange(model) {
  	// console.log(this)
  	// console.log(model)



  	/*
    this.setState({
      model: model
    });
    */
  }

  handleFieldChange(value) {

  }

  componentWillUpdate(nextProps, nextState) {
  	// console.log('willdup')

	if (nextState.open === true && this.state.open === false) {
	  this.props.onWillOpen();
	}
  }

  componentDidUpdate(nextProps) {
	var _thisEditorComponent = this
	var sid = localStorage.getItem('sid')

	// console.log(this.props)
	// console.log(nextProps)

		if (this.props !== nextProps) {
				// console.log(nextProps)

			// console.log(this.props.selectedDocument)

			if (this.props.selectedDocument !== '' && !this.props.uploadingDocument) {
			    this.setState({ 
			    	selectedDocument: this.props.selectedDocument
			    })


				var finalURL = API + '/read-xml-wholedocument'

				// console.log('Current document')
				// console.log(this.props.selectedDocument.label)
				// console.log(this.props.selectedDocument.value)


				    this.setState({ 
				    	loading: 'Loading Document Sections... (Larger Documents will take a moment, please wait.)'
				    })

				    this.serverRequest = 
				    	axios
				        .post(finalURL, {
				        	headers: { 'Cache-Control': 'no-cache' },
				        	responseType: 'arraybuffer',
							data: {
								sid: sid,
								documentName: this.props.selectedDocument.label,
								documentId: this.props.selectedDocument.value,
							}
				        })
				        .then(function(result) { 
				        	if (result) {
				        		// console.log(result)
					        	var numberOfResults = result.data.result.recordset.length-1   

					        	// console.log(result.data.result)

					        	if (result.data.result.recordset[0]) {
								    _thisEditorComponent.setState({ 
								    	documentId: result.data.result.recordset[0].DocumentId
								    })

									const parser = new DOMParser()

									var allContent = result.data.result.recordset
									var decoded = result.data.result.recordset[numberOfResults].Content

									if (decoded) {
										var decodedXML = utf8_for_xml(decoded)
									    var repairingNeeded = decodedXML.match(/<img (.*)><figcaption/g)
									    if (repairingNeeded)
									      decodedXML = decodedXML.replace('><figcaption', '></img><figcaption')

										decodedXML = parser.parseFromString(decodedXML, 'text/xml');
										decodedXML = decodedXML.querySelector('documentRoot').childNodes

										_thisEditorComponent.setState({
							        		allContent: '',
							            	model: ''
							            })
							          
							        	_thisEditorComponent.setState({
							        		allContent: allContent,
							            	model: decodedXML,
							            	editing: 'yes',
							            	loading: ''
							        	})
						        	}
						        } else {
						        	const parser = new DOMParser()
						        	decoded = '<documentRoot><documentSection><title>New Document Title</title><content><div class="section">New Document Content</div></content></documentSection></documentRoot>'

									decodedXML = utf8_for_xml(decoded)
									decodedXML = parser.parseFromString(decodedXML, 'text/xml');
									// console.log(decodedXML)
									decodedXML = decodedXML.querySelector('documentRoot').childNodes

									// console.log(decodedXML)
						          
						        	_thisEditorComponent.setState({
						        		allContent: allContent,
						            	model: decodedXML,
						            	editing: 'yes',
						            	loading: ''
						        	})
						        }
				        	}
				        })
				
			} else {
			    _thisEditorComponent.setState({ 
			    	model: '',
			    	loading: '',
			    	editing: ''
			    })
			}
		}
    }

	componentDidMount(props) {
  		// console.log('Did Mount Props:')
  		// console.log(props)
	}

	shouldComponentUpdate(nextProps) {
		// console.log(nextProps)
		// console.log(this.state.model.length)

		if (nextProps !== this.state.model.length)
			return true

		/*
		console.log('Yes, should update:')
		console.log(nextProps)
		console.log(this.state.model.length)

		var allTheContent = document.getElementsByClassName('ck-content')

		console.log(allTheContent.length)

		return true
		*/
	}


	saveDocument = saveDocument => {
		var finalURL = API + '/text-section-edit'
		var sid = localStorage.getItem('sid')

		var allTheContent =		document.getElementsByClassName('ck-content')
		var allTheTitles =		document.getElementsByClassName('sectionTitle')
		var saveButton =		document.getElementsByClassName('saveDocument')
		var publishDocument =	document.getElementsByClassName('publishDocument')
		var rebuiltContent = ''
		var action =			saveDocument.target.value

		saveButton[1].className =		'col-md-12 btn saveDocument btn-disabled'
		publishDocument[0].className =	'col-md-12 btn publishDocument btn-disabled'
		Object.entries(allTheTitles).map(e => allTheTitles[e[0]].style.background = '#eee' )
		Object.entries(allTheContent).map(e => allTheContent[e[0]].style.background = '#eee' )

		Object.entries(allTheContent).map(([key, value]) => {
			rebuiltContent += '<documentSection sectionNumber="section' + Math.round(parseInt(key)+1) + '">'

				if (allTheTitles[key])
					rebuiltContent += '<title>' + allTheTitles[key].value + '</title>'
				rebuiltContent += '<content><div class="section">'
				+ value.innerHTML.replace(/&nbsp;/gm,' ').replace(/<br>/gm,'').replace(/<br data-cke-filler="true">/gm,'')
				+ '</div></content>'
				+ '</documentSection>'

				return ''
			}
		)
		
		/*
		const parser = new DOMParser()

		rebuiltContent = parser.parseFromString(rebuiltContent, 'text/xml');
		*/

		rebuiltContent = '<documentRoot>' + rebuiltContent + '</documentRoot>'

	    // console.log(this.state.documentId)
		// console.log(rebuiltContent)

		// console.log(this.state)

	    this.serverRequest = 
	      axios
	        .post(finalURL, {
	        	headers: { 'Cache-Control': 'no-cache' },
	        	responseType: 'arraybuffer',
				data: {
					sid: sid,
					documentId: this.state.selectedDocument.value,
					content: rebuiltContent,
					action: action
				}
	        })
	        .then(function(result) { 

	        	// if result all good then reset the workspace

				saveButton[1].className = 'col-md-12 btn btn-primary saveDocument'
				publishDocument[0].className = 'col-md-12 btn btn-primary publishDocument'
				Object.entries(allTheTitles).map(e => allTheTitles[e[0]].style.background = '#fff')
				Object.entries(allTheContent).map(e => allTheContent[e[0]].style.background = '#fff')

	        	// console.log(result)
	        })
	}	

	addSection = addSection => {
		var currentModel = this.state.model 
		var newNodeList = []
		var newSectionElement = '<documentSection sectionNumber="section' + currentModel.length + '"><title>Title</title><content><div class="section">Content</div></content></documentSection>'
		//var newSectionElementfromBase = ''
		//newSectionElementfromBase.createElement("documentSection")

		const parser = new DOMParser()
		newSectionElement = utf8_for_xml(newSectionElement)
		newSectionElement = parser.parseFromString(newSectionElement, 'text/xml');
		
		// console.log(newSectionElement)
		// console.log(newSectionElementfromBase)

		Object.entries(currentModel).map(e => {
			newNodeList[e[0]] = e[1]

			return e[1]
		})

		newNodeList.push(newSectionElement.childNodes[0])

		this.setState({
			model: newNodeList 
		})



		//currentModel.appendChild('<title>New Section Title</title><content>New Section Content</content>')

		/*
		currentModel[currentModel.length] = { documentsection : '<title>New Section Title</title><content>New Section Content</content>' }

		this.setState({
			model: currentModel 
		})

		console.log(currentModel)
		console.log(typeof currentModel)
		*/
	}

	removeSection = removeSection => {
		var originalClass = removeSection.target.className
		var sectionNumbertoRemove = originalClass.match(/sectionEditor([0-9]+)/)
		// console.log(sectionNumbertoRemove)
		if (sectionNumbertoRemove) {
			sectionNumbertoRemove = parseInt(sectionNumbertoRemove[1])

			var currentModel = this.state.model 
			var newNodeList = []
			//newSectionElementfromBase.createElement("documentSection")
			Object.entries(currentModel).map(e => {
				newNodeList[e[0]] = e[1]

				return e[1]
			})

			// newNodeList.splice(sectionNumbertoRemove, 1)
			delete newNodeList[sectionNumbertoRemove]

			this.setState({
				model: newNodeList 
			})

			this.forceUpdate()
		}
	}

  render () {
  	var allSections = this.state.model

    return (
    		// value.querySelector('.section').innerHTML

    		<div className="editorLayout row">
	    		<div className="editorSections col-md-8">
	    			<div>{ this.state.loading }</div>

	    			{ this.state.editing ? Object.entries(allSections).map(([key, value]) =>
	    				<div className={"editingSection sectionEditor"+key} id={"sectionEditor"+key} key={"sectionEditor"+key}>
	    					{ value.querySelector('title') ?
	    					<div className="removeSection pull-right col-md-1 text-right" onClick={this.removeSection}><i className={"fa fa-times sectionEditor"+key}></i></div>
	    					: null }	

	    					{ value.querySelector('title') ?
	    					<label htmlFor={"sectionTitle"+key} className="col-md-12">
	    						Section Title: 
		    						<input className="form-control sectionTitle" name={"sectionTitle"+key}
		    							key={ this.state.loading ? 'notLoadedYet' : "sectionTitle"+key }
		    							type="text" 
		    							defaultValue={value.querySelector('title').innerHTML} />
		    					
		    				</label>
		    				: null }

		    				<div className="col-md-12">
		    					{ value.querySelector('.section') ?
			    				<CKEditor
				    				editor={ ClassicEditor }
				    				config={ editorConfiguration }
				                    data={value.querySelector('.section').innerHTML}
				                    onInit={ editor => {
				                    } }
				                    onChange={this.handleModelChange}
					            	key={"sectionContent"+key}
					            />
					            : null }
					        </div>
				        </div>
		            ) : null }
	           </div>

	           <div className="editorControl col-md-4">
	           		<div className="sticky-top">
		           		<br className="clear" />

		                { this.state.editing ? 
		                	<div className="col-md-12">
		                		<input type="submit" value="Add Section" className="col-md-12 btn btn-primary saveDocument"  onClick={ this.addSection } />
		                	</div>
		                : null }

		                { this.state.editing ? 
		                	<div className="col-md-12">
		                		<input type="submit" value="save" className="col-md-12 btn btn-primary saveDocument"  onClick={ this.saveDocument } />
		                	</div>
		                : null }

		                { this.state.editing ? 
		                	<div className="col-md-12">
		                		<input type="submit" value="publish" className="col-md-12 btn btn-primary publishDocument"  onClick={ this.saveDocument } />
		                	</div>
		                : null }
	                </div>
	           </div>
	        </div>
        )
  }
}

class Editor extends Component {
	constructor(props) {
		super(props);

	    this.state = {
	      selectedOptionState: '',
	      selectedOptionCounty: '',
	      selectedOptionProblemId: '',
	      selectedOptionSubCategoryId: '',
	      isSelectedProblem: '',
	      isSelectedCounty: '',
		  errorMessageCounty: '',
		  StateId: '',
		  CountyId: '',
	      selectedDocument: '',
	      uploadingDocument: false,
	      testTest: '',
	      admin: '',
	      accept: '.docx, .pdf',
	      filesPDFs: [],
	      filesDOCXs: [],
	      dropzoneActive: false,
	      dropzoneActivePDF: false,
	      session_id: '',
	      authorized: true,
	      authorizationMessage: 'Checking authorization...'
	    }

	    this.handleChangeDocument = this.handleChangeDocument.bind(this);
	}

	componentDidMount() {
		var _thisComponent = this

// return inputStr.replace(/[^\x09\x0A\x0D\x20-\xFF\x85\xA0-\uD7FF\uE000-\uFDCF\uFDE0-\uFFFD]/gm, '');



		/*
		// This needs to be async (I thought it was...)
	    setTimeout( function() {
	    	// console.log(_thisComponent.props)

		    // const { isAuthenticated } = _thisComponent.props.auth;
			// var definitelyLoggedin = isAuthenticated()

		    _thisComponent.setState({ profile: {} })
		    const { userProfile, getProfile } = _thisComponent.props.auth

		    if (!userProfile) {
		      getProfile((err, profile) => {
		        _thisComponent.setState({ profile })

		        // console.log(profile)

		        if (profile && profile.app_metadata && profile.app_metadata.role === 'administrator') {
		      		_thisComponent.setState({ authorized: true })
		        } else {
		      		_thisComponent.setState({
		      			authorizationMessage: "Sorry, you're not authorized to edit."
		      		})
		        }
		      })

		    } else {
		      _thisComponent.setState({ profile: userProfile })

		      // console.log(userProfile)
		    }
	    }, 1000)
		*/	    

		// console.log('Loading from localStorage instead')

	    
	    var $role = localStorage.getItem('role') 

	    if ($role) {
	      // _thisComponent.setState({ profile: userProfile })

	      // console.log('userProfile')
	      // console.log(localStorage.getItem('profile'))

	      const profile = JSON.parse(localStorage.getItem('profile'))
	      // console.log(profile)

	      if ($role !== 'administrator') {
	    	this.setState({
	    		authorizationMessage: "Please log in as administrator."
	    	})
	      } else if ($role === 'administrator') {
	    	this.setState({
	    		authorizationMessage: "Logged in.",
	    		role: $role

	    	})
	      }

	      _thisComponent.setState({ profile })
	    }

	    const { renewSession } = _thisComponent.props.auth

	    if (localStorage.getItem('isLoggedIn') === 'true') {
	      renewSession()
	    }
	}

	getStates = () => {
		var sid = localStorage.getItem('sid')

		//console.log(input)
		//console.log(_thisComponent.state.selectedOptionState)
	    
	      return axios.post(API + `/documents`, {
				data: {
					sid: sid
				}
	          })
	        .then((response) => {
	          // console.log(response)
	          //
	          var statesList = []
	          Object.values(response.data.files.recordset).forEach(function(trait) {
	            statesList.push({ value: trait.StateId, label: trait.State });
	          });
	          return statesList;
	        }).then((json) => {
	          return { options: json };
	        });
	}

	getCounties = (input) => {
		var sid = localStorage.getItem('sid')

		// console.log(input)
		// console.log(this)
		// console.log(_thisComponent.state.selectedOptionState)
	    
	    if (this.state.selectedOptionState) {
	      return axios.post(API + `/documents/${this.state.selectedOptionState.label}`, {
				data: {
					sid: sid
				}
	          })
	        .then((response) => {
	          // console.log(response)
	          //
	          var countiesList = []
	          Object.values(response.data.files.recordset).forEach(function(trait) {
	            countiesList.push({ value: trait.CountyId, label: titleCase(trait.county) });
	          });
	          return countiesList;
	        }).then((json) => {
	          return { options: json };
	        });
	    }
	}

	getProblems = (input) => {
		var sid = localStorage.getItem('sid')

	      return axios.post(API + `/issues`, {
				data: {
					sid: sid
				}
	          })
	        .then((response) => {
	          // console.log(response)
	          //
	          var issuesList = []
	          Object.values(response.data.files.recordset).forEach(function(trait) {
	            issuesList.push({ value: trait.ProblemId, label: titleCase(trait.ProblemType) });
	          });
	          return issuesList;
	        }).then((json) => {
	          return { options: json };
	        });
	}

	getSubcategories = (input) => {
		var sid = localStorage.getItem('sid')

		// console.log(input)
		// console.log(this.state)
	    
	    if (this.state.selectedOptionProblemId) {
	      return axios.post(API + `/documents/${this.state.selectedOptionState.label}/${this.state.selectedOptionCounty.label}/${this.state.selectedOptionProblemId.label}/subCategory`, {
	              data: {
	              	sid: sid,
	              	ProblemId: this.state.ProblemId,
	              	StateId: this.state.StateId,
	              	CountyId: this.state.CountyId
	              }
	          })
	        .then((response) => {
	          // console.log(response.data.files)
	          
	          var subIssuesList = []
	          Object.values(response.data.files).forEach(function(trait) {
	            subIssuesList.push({ subvalue: trait.SubcategoryId, value: trait.SubcategoryId, label: titleCase(trait.Subcategory) });
	          });
	          return subIssuesList
	        }).then((json) => {
	          return { options: json }
	        })
	    }
	}

	getDocument = (input) => {
		var sid = localStorage.getItem('sid')

		// console.log(input)
		// console.log(this.state)
	    
	      return axios.post(API + `/documents/editor`, {
	              data: {
	              	sid: sid,
	              	StateId: 		this.state.StateId,
	              	CountyId: 		this.state.CountyId,
	              	ProblemId: 		this.state.ProblemId,
	              	SubcategoryId: 	input
	              }
	          })
	        .then((response) => {
	        	// console.log(response)
	        		
			    var documentList = []

			    if (response && response.data.files && response.data.files.recordset[0]) {
				    Object.values(response.data.files.recordsets[0]).forEach(function(trait) {
				      documentList.push({ value: trait.DocumentId, label: trait.ActualDocumentName })
				    });

				
				    this.setState({ 
				      selectedDocument: documentList[0],
				      selectedDocumentId: response.data.files.recordset[0].DocumentId
				    })
			    }
			  })
	}

	onDragEnter() {
		this.setState({
		  //dropzoneActive: true
		});
	}

	onDragLeave() {
		this.setState({
		  //dropzoneActive: false
		});
	}

	onDragEnterPDF() {
		this.setState({
		  //dropzoneActivePDF: true
		});
	}

	onDragLeavePDF() {
		this.setState({
		  //dropzoneActivePDF: false
		});
	}

	onDrop(files) {
		var selectedDocument = this.state.selectedDocument

		// console.log(this.state)

		if (selectedDocument) {
		  //

		  this.setState({
		    filesDOCXs: files,
		    dropzoneActive: false,
		    selectedDocument: ''
		  })

		  var data = new FormData()
		  data.append('file', files[0])
		  data.append('filename', selectedDocument + '.docx')
		  data.append('directDocumentId', this.state.selectedDocumentId)

		  fetch(API + '/document-update', {
		    method: 'POST',
		    headers: {
		      'authorization': process.env.REACT_APP_API_KEYEXTENDED
		    },
		    body: data,
		  }).then((response) => {
		  	// console.log(response)

		  	/*
		    response.json().then((body) => {
		      //this.setState({ imageURL: API + '/document-update' + `${body.file}` });
		    });
		    */
		  })
		}
	}

	onDropPDF(files) {


	  //

	  this.setState({
	    filesPDFs: files,
	    dropzoneActivePDF: false
	  })

	  var data = new FormData()
	  data.append('file', files[0])
	  data.append('filename', files[0].name)

	  fetch(API + '/document-update', {
	    method: 'POST',
	    headers: {
	      'authorization': process.env.REACT_APP_API_KEYEXTENDED
	    },
	    body: data,
	  }).then((response) => {
	  	console.log(response)
	    response.json().then((body) => {
	    	console.log(body)
	      //this.setState({ imageURL: API + '/document-update' + `${body.file}` });
	    })
	  })
	}

	applyMimeTypes(event) {
		this.setState({
		  accept: event.target.value
		})
	}

	handleChangeDocument = selectedOptions => {
		// console.log(selectedOptions.value)

		// console.log(selectedOptions)
		if (selectedOptions) {
		    this.setState({ 
		      selectedDocument: selectedOptions,
		      selectedDocumentId: selectedOptions.value
		    })
	    }
	}

	handleChangeState = (selectedOptionState) => {
		// console.log(selectedOptionState)

		  this.setState({ 
		    selectedOptionCounty: '',
		    CountyId: ''
		  })

		if (selectedOptionState) {
		  this.setState({ 
		    selectedOptionState,
		    StateId: selectedOptionState.value
		  })

		  this.forceUpdate()
		} else {
		  this.setState({ 
		    isSelectedState: 'invalid',
		    selectedOptionState,
		    selectedDocument: '',
		    selectedDocumentId: ''
		  })
		}
	}

	handleChangeCounty = (selectedOptionCounty) => {
		if (selectedOptionCounty) {
		  this.setState({ 
		    errorMessageCounty: '',
		    selectedOptionCounty,
		    CountyId: selectedOptionCounty.value
		  }
		   //,() => 
		  )
		} else if (!this.state.selectedOptionState) {
		  this.setState({ 
		    isSelectedCounty: 'invalid',
		    errorMessageCounty: 'Add the property\'s state first!',
		    selectedOptionCounty: ''
		  })
		} else {
		  this.setState({ 
		    isSelectedCounty: 'invalid',
		    errorMessageCounty: 'Add the property\'s county!',
		    selectedOptionCounty: '',
		    selectedDocument: '',
		    selectedDocumentId: ''
		  })
		}
	}

	handleChangeProblemId = (selectedOptionProblemId) => {
		if (selectedOptionProblemId) {
		  this.setState({ 
		    selectedOptionProblemId,
		    ProblemId: selectedOptionProblemId.value
		  })

		  this.forceUpdate()
		} else {
		  this.setState({ 
		    isSelectedProblem: 'invalid',
		    selectedOptionProblemId,
		    selectedDocument: '',
		    selectedDocumentId: ''
		  })
		}
	}

	handleChangeSubCategoryId = (selectedOptionSubCategoryId) => {
		if (selectedOptionSubCategoryId && selectedOptionSubCategoryId.value) {

			// console.log(selectedOptionSubCategoryId)

		  this.setState({ 
		    selectedOptionSubCategoryId,
		    SubcategoryId: selectedOptionSubCategoryId.value
		  })

		  this.getDocument(selectedOptionSubCategoryId.value)

		  this.forceUpdate()
		} else {
		  this.setState({ 
		    selectedOptionSubCategoryId,
		    selectedDocument: '',
		    selectedDocumentId: ''
		  })
		}
	}

	render() {
        var adminLinks = ['editor','new document','UWAL']

		var { filesPDFs, filesDOCXs, dropzoneActive, dropzoneActivePDF, selectedDocument, authorizationMessage, selectedOptionState, selectedOptionCounty, selectedOptionProblemId, selectedOptionSubCategoryId, highestDocumentID } = this.state

	    // if (this.state.profile)
	    	// console.log(this.state.profile.app_metadata)

	    // this.state.profile && this.state.profile.app_metadata.role === 'administrator'

		if (this.state.role === 'administrator') { 
		    return (
		    	<div>

			    	<div className="container editor-layout">
			    		<ul className="nav justify-content-center text-center">
						  {Object.entries(adminLinks).map(([key, value]) =>
		              	<li className={ 'nav-link col-md-3 ' + key } key={ key }><a href={ '/admin/' + value.replace(' ', '-') }>{ value }</a></li>
						  )}
						</ul>

			    		<div>
				            <Async
				                name="selectMultiIssues"
				                value={ selectedDocument }
				                placeholder="Choose a Document"
				                onChange={ this.handleChangeDocument }
				                loadOptions={ getAllDocuments }
				                className={ 'placeholder' }
				            />

		                	<p>Start by selecting the State, County, Problem, and then SubCategory to select the document.</p>

		                    <div className="form-group">
		                    	<br />

		                        <label htmlFor="StateId" className="col-md-6">
		                        	State
		                            <Async
		                                name="StateId"
		                                placeholder="Choose State"
		                                value={ selectedOptionState }
		                				onChange={ this.handleChangeState }
		                				loadOptions={ this.getStates }
					                  	className={ 'placeholder' }                
		                            />
		                        </label>

		                        <br className="clear" />

		                        { selectedOptionState ?
					            <label htmlFor="CountyId" className={ "col-md-6 " }>
					              County:

					              <Async
					                  name="CountyId"
					                  placeholder="Choose County"
					                  value={ selectedOptionCounty }
					                  onChange={ this.handleChangeCounty }
					                  loadOptions={ this.getCounties }
					                  className={ 'placeholder' }                
					                //required
					              />
					            </label>
					            : null }

					            <br className="clear" />

					          { selectedOptionCounty ?
			                  <label htmlFor="problemId" className={ "col-md-6 " }>
			                    Problem Type:

			                    <Async
			                        name="Problem"
			                        placeholder="Choose Problem"
			                        value={ selectedOptionProblemId }
			                        onChange={ this.handleChangeProblemId }
			                        loadOptions={ this.getProblems }
			                        className={ 'placeholder' }                
			                      //required
			                    />

			                    <small className={ 'text-danger ' } key="problemId"></small>
			                  </label>
					          : null }

					          <br className="clear" />

					          { selectedOptionProblemId ?
			                  <label htmlFor="SubCategoryId" className={ "col-md-6 " }>
			                    Problem SubCategory:

			                    <Async
			                        name="SubCategory"
			                        placeholder="Choose Problem"
			                        value={ selectedOptionSubCategoryId }
			                        onChange={ this.handleChangeSubCategoryId }
			                        loadOptions={ this.getSubcategories }
			                        className={ 'placeholder' }                
			                      //required
			                    />

			                    <small className={ 'text-danger ' } key="SubCategoryId"></small>
			                  </label>
			                  : null }

					          <br className="clear" />

			                  <label htmlFor="DocumentId" className={ "col-md-6" } style={{'display': 'none'}}>
			                    ProblemId:

		                        <input
		                            className="form-control"
		                            placeholder={ highestDocumentID }
		                            name="problemId"
		                            defaultValue={ highestDocumentID }
		                            type="text"
		                            onChange={this.fieldUpdate}
		                        />

			                  </label>
		                    </div>

				            { this.state.selectedDocument ? 
				            <div>
					            <Dropzone
					              accept=".docx"
					              onDrop={this.onDrop.bind(this)}
					              // onDragEnter={this.onDragEnter.bind(this)}
					              // onDragLeave={this.onDragLeave.bind(this)}
					              className="dropZone"
					            >
					              { dropzoneActive && <div>Drop files...</div> }
					              <div>
					                <h1>Replace a Document</h1>

					                <h2>Dropped files</h2>
					                <ul>
					                  {
					                    filesDOCXs.map(f => <li key={f.name}>{f.name} - {f.size} bytes</li>)
					                  }
					                </ul>

					              </div>
					            </Dropzone>

					            <Dropzone
					              accept=".pdf"
					              onDrop={this.onDropPDF.bind(this)}
					              // onDragEnter={this.onDragEnterPDF.bind(this)}
					              // onDragLeave={this.onDragLeavePDF.bind(this)}
					              className="dropZone"
					            >
					              { dropzoneActivePDF && <div>Drop files...</div> }
					              <div>
					                <h1>Upload a .pdf (to be linked to.)</h1>

					                <h2>Dropped files</h2>
					                <ul>
					                  {
					                    filesPDFs.map(f => <li key={f.name}><a href={ "/api/" + f.name }>{f.name}</a> - Link to file: { "/api/" + f.name }</li>)
					                  }
					                </ul>

					              </div>
					            </Dropzone>
				            </div>
				            : null }



			    			<EditorComponent selectedDocument={this.state.selectedDocument} selectedDocumentId={this.state.selectedDocumentId} />
			    		</div>
		    		</div>
	    		</div>
	    	)
    	} else {
	    	return (
	    		<div>
	    			<div className="nav justify-content-center">
	    				{ authorizationMessage }
	    			</div>
	    		</div>
	    	)
    	}
	}
}

export default withRouter(Editor);