import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import { Async } from 'react-select';
// import Select from 'react-select'

import 'react-select/dist/react-select.css';

import 'font-awesome/css/font-awesome.css'

var statesList = []
var countiesList = []
var issuesList = []
var subIssuesList = []
var _thisComponent = this

var API = '';

if (window.location.hostname === 'localhost') {
  API = 'http://' + window.location.hostname + ':3001/api';
} else {
  API = 'https://' + window.location.hostname + '/api';
}

var getStates = () => {
	 var sid = localStorage.getItem('sid')

	//console.log(input)
	//console.log(_thisComponent.state.selectedOptionState)
    
      return axios.post(API + `/documents`, {
        data: {
          sid: sid
        }
      })
        .then((response) => {
          // console.log(response)
          //
          statesList.length = 0;
          Object.values(response.data.files.recordset).forEach(function(trait) {
            statesList.push({ value: trait.StateId, label: trait.State });
          });
          return statesList;
        }).then((json) => {
          return { options: json };
        });
}

var getCounties = (input) => {
	 var sid = localStorage.getItem('sid')

	//console.log(input)
	//console.log(_thisComponent.state.selectedOptionState)
    
    if (_thisComponent.state.selectedOptionState) {
      return axios.post(API + `/documents/${_thisComponent.state.selectedOptionState.label}`, {
        data: {
          sid: sid
        }
      })
        .then((response) => {
          // console.log(response)
          //
          countiesList.length = 0;
          Object.values(response.data.files.recordset).forEach(function(trait) {
            countiesList.push({ value: trait.CountyId, label: titleCase(trait.county) });
          });
          return countiesList;
        }).then((json) => {
          return { options: json };
        });
    }
}

var getProblems = (input) => {
	 var sid = localStorage.getItem('sid')

      return axios.post(API + `/issues`, {
        data: {
          sid: sid
        }
      })
        .then((response) => {
          // console.log(response)
          //
          issuesList.length = 0;
          Object.values(response.data.files.recordset).forEach(function(trait) {
            issuesList.push({ value: trait.ProblemId, label: titleCase(trait.ProblemType) });
          });
          return issuesList;
        }).then((json) => {
          return { options: json };
        });
}

var getSubcategories = (input) => {
	 var sid = localStorage.getItem('sid')

	// console.log(input)
	// console.log(_thisComponent.state)
    
    if (_thisComponent.state.selectedOptionProblemId) {
      return axios.post(API + `/documents/${_thisComponent.state.selectedOptionState.label}/${_thisComponent.state.selectedOptionCounty.label}/${_thisComponent.state.selectedOptionProblemId.label}/subCategory`, {
              data: {
              	sid: sid, 
              	ProblemId: _thisComponent.state.ProblemId,
              	StateId: _thisComponent.state.StateId,
              	CountyId: _thisComponent.state.CountyId
              }
          })
        .then((response) => {
          // console.log(response)
          //
          subIssuesList.length = 0;
          Object.values(response.data.files).forEach(function(trait) {
            subIssuesList.push({ subvalue: trait.SubcategoryId, value: trait.SubcategoryId, label: titleCase(trait.Subcategory) });
          });
          return subIssuesList
        }).then((json) => {
          return { options: json }
        })
    }
}

var checkDocumentExists = (input) => {
	 var sid = localStorage.getItem('sid')

	// console.log(input)
	// console.log(_thisComponent.state)
    
    if (input) {
	    return axios.post(API + `/documents/editor`, {
              data: {
              	sid: sid,
              	StateId: 		_thisComponent.state.StateId,
              	CountyId: 		_thisComponent.state.CountyId,
              	ProblemId: 		_thisComponent.state.ProblemId,
              	SubcategoryId: 	input
              }
          })
        .then((response) => {
          console.log(response)

          if (!response.data.error && response.data.files.recordset[0]) {
          	_thisComponent.setState({
          		checkDocumentExists: true
          	})
          }

          return response
        }).then((json) => {
          return { options: json }
        })
    }
}


var getHighestDocumentID = async() => {
	 var sid = localStorage.getItem('sid')
	//console.log(input)


	var highestID = async() => axios.post(API + `/all-documents`, {
        data: {
          sid: sid
        }
	})
	.then((response) => {
	  // console.log(response.data.files.recordset)

	  var recordsLength = response.data.files.recordset.length

	  // console.log(response.data.files.recordset[recordsLength-1])

	  return response.data.files.recordset[recordsLength-1].DocumentId
	}).then((json) => {
	  
      return json
    })

    const id = await highestID()

	return id
}


function titleCase(str) {
   var splitStr = str.toLowerCase().split(' ');
   for (var i = 0; i < splitStr.length; i++) {
       // You do not need to check if i is larger than splitStr length, as your for does that for you
       // Assign it back to the array
       splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
   }
   // Directly return the joined string
   return splitStr.join(' '); 
}


class Documents extends Component {
	constructor(props) {
		super(props)


	    this.state = {
	      selectedOptionState: '',
	      selectedOptionCounty: '',
	      selectedOptionProblemId: '',
	      selectedOptionSubCategoryId: '',
	      isSelectedProblem: '',
	      isSelectedCounty: '',
    	  errorMessageCounty: '',
    	  StateId: '',
    	  CountyId: '',
    	  submitMessage: '',
    	  checkDocumentExists: '',
	      authorized: true,
	      authorizationMessage: 'Checking authorization...'
	    }

	    this.handleSubmit = this.handleSubmit.bind(this)
	}

	componentDidMount() {
		_thisComponent = this

	    var $role = localStorage.getItem('role') 

	    if ($role) {
	      // _thisComponent.setState({ profile: userProfile })

	      // console.log('userProfile')
	      // console.log(localStorage.getItem('profile'))

	      const profile = JSON.parse(localStorage.getItem('profile'))
	      // console.log(profile)

	      if ($role !== 'administrator') {
	    	this.setState({
	    		authorizationMessage: "Please log in as administrator."
	    	})
	      } else if ($role === 'administrator') {
	    	this.setState({
	    		authorizationMessage: "Logged in.",
	    		role: $role

	    	})
	      }

	      _thisComponent.setState({ profile })
	    }

		getHighestDocumentID().then((id) => { 
			console.log(id+1)

	    	_thisComponent.setState({
	    		highestDocumentID: id,
	    		DocumentId: id+1
	    	})
		})

	    const { renewSession } = _thisComponent.props.auth;

	    if (localStorage.getItem('isLoggedIn') === 'true') {
	      renewSession();
	    }
	}

    fieldUpdate = (fieldProperty) => {
    	var $name =	 fieldProperty.target.name
    	var $value = fieldProperty.target.value

        this.setState({ 
        	[$name]: $value 
        })

        // console.log($name)
        // console.log($value)
    }

	handleSubmit(event, sid) {
	    event.preventDefault()

	 	console.log(sid)

	    // const data = new FormData(event.target)

	    axios.post(API + `/document-creation`, {
	    	sid: sid,
	        data: this.state
	      })
	      .then((response) => {
	      	this.setState({
	      		submitMessage: 'New document created, click over to the editor to add content.'
	      	})

	      	this.forceUpdate()
	      }).catch((response) => {
	      	this.setState({
	      		submitMessage: 'Error creating the document. Refresh and try again.'
	      	})
	        // console.log(response)
	      })

	    // console.log(this.state)
    }

	handleChangeState = (selectedOptionState) => {
		// console.log(selectedOptionState)

		if (selectedOptionState) {
		  this.setState({ 
		    selectedOptionState,
		    StateId: selectedOptionState.value
		  })

		  this.forceUpdate()
		} else {
		  this.setState({ 
		    isSelectedState: 'invalid',
		    selectedOptionState
		  })
		}
	}

	handleChangeCounty = (selectedOptionCounty) => {
		// console.log(selectedOptionCounty)

		if (selectedOptionCounty) {
		  this.setState({ 
		    errorMessageCounty: '',
		    selectedOptionCounty,
		    CountyId: selectedOptionCounty.value
		  }
		   //,() => 
		  )
		} else if (!_thisComponent.state.selectedOptionState) {
		  this.setState({ 
		    isSelectedCounty: 'invalid',
		    errorMessageCounty: 'Add the property\'s state first!',
		    selectedOptionCounty: ''
		  })
		} else {
		  this.setState({ 
		    isSelectedCounty: 'invalid',
		    errorMessageCounty: 'Add the property\'s county!',
		    selectedOptionCounty: ''
		  })
		}
	}

	handleChangeProblemId = (selectedOptionProblemId) => {
		console.log(selectedOptionProblemId)

		if (selectedOptionProblemId) {
		  this.setState({ 
		    selectedOptionProblemId,
		    ProblemId: selectedOptionProblemId.value
		  })

		  this.forceUpdate()
		} else {
		  this.setState({ 
		    isSelectedProblem: 'invalid',
		    selectedOptionProblemId
		  })
		}
	}

	handleChangeSubCategoryId = (selectedOptionSubCategoryId) => {
		console.log(selectedOptionSubCategoryId)

		if (selectedOptionSubCategoryId && selectedOptionSubCategoryId.value) {
		  console.log(selectedOptionSubCategoryId)

		  this.setState({ 
		    selectedOptionSubCategoryId,
		    SubcategoryId: selectedOptionSubCategoryId.value,
		    DocumentName: selectedOptionSubCategoryId.label,
		    ActualDocumentName: selectedOptionSubCategoryId.label,
		    DisplayDocumentName: selectedOptionSubCategoryId.label
		  })

		  checkDocumentExists(selectedOptionSubCategoryId.value)

		  this.forceUpdate()
		} else {
		  this.setState({ 
		    selectedOptionSubCategoryId
		  })
		}
	}

	render() {
		// var allInputProperties = ['DocumentName','ActualDocumentName','DisplayDocumentName']
		// var allSelectProperties = ['StateId','CountyId']

	    var { selectedOptionState, selectedOptionCounty, selectedOptionProblemId, selectedOptionSubCategoryId, highestDocumentID, submitMessage, checkDocumentExists, authorizationMessage } = this.state

	    // console.log(highestDocumentID)
	    // console.log(selectedOptionState)

        var adminLinks = ['editor','new document','UWAL']

		if (this.state.role === 'administrator') { 
		    return (
		    	<div className="container editor-layout">
		    		<ul className="nav justify-content-center text-center">
					  {Object.entries(adminLinks).map(([key, value]) =>
		              	<li className={ 'nav-link col-md-3 ' + key } key={ key }><a href={ '/admin/' + value.replace(' ', '-') }>{ value }</a></li>
					  )}
					</ul>

					{ checkDocumentExists ?
						<p>This document already exists, click over to the editor to update or replace it.</p>
					:
				    !submitMessage ?
	                <form onSubmit={ this.handleSubmit }>
	                	<p>Start by selecting the State, County, Problem, and then SubCategory for the new document.</p>

	                    <div className="form-group">
	                    	<br />

	                        <label htmlFor="StateId" className="col-md-6">
	                        	State
	                            <Async
	                                name="StateId"
	                                placeholder="Choose State"
	                                value={ selectedOptionState }
	                				onChange={ this.handleChangeState }
	                				loadOptions={ getStates }
				                  	className={ 'placeholder' }                
	                            />
	                        </label>

	                        <br className="clear" />

	                        { selectedOptionState ?
				            <label htmlFor="CountyId" className={ "col-md-6 " }>
				              County:

				              <Async
				                  name="CountyId"
				                  placeholder="Choose County"
				                  value={ selectedOptionCounty }
				                  onChange={ this.handleChangeCounty }
				                  loadOptions={ getCounties }
				                  className={ 'placeholder' }                
				                //required
				              />
				            </label>
				            : null }

				            <br className="clear" />

				          { selectedOptionCounty ?
		                  <label htmlFor="problemId" className={ "col-md-6 " }>
		                    Problem Type:

		                    <Async
		                        name="Problem"
		                        placeholder="Choose Problem"
		                        value={ selectedOptionProblemId }
		                        onChange={ this.handleChangeProblemId }
		                        loadOptions={ getProblems }
		                        className={ 'placeholder' }                
		                      //required
		                    />

		                    <small className={ 'text-danger ' } key="problemId"></small>
		                  </label>
				          : null }

				          <br className="clear" />

				          { selectedOptionProblemId ?
		                  <label htmlFor="SubCategoryId" className={ "col-md-6 " }>
		                    Problem SubCategory:

		                    <Async
		                        name="SubCategory"
		                        placeholder="Choose Problem"
		                        value={ selectedOptionSubCategoryId }
		                        onChange={ this.handleChangeSubCategoryId }
		                        loadOptions={ getSubcategories }
		                        className={ 'placeholder' }                
		                      //required
		                    />

		                    <small className={ 'text-danger ' } key="SubCategoryId"></small>
		                  </label>
		                  : null }

				          <br className="clear" />

		                  <label htmlFor="DocumentId" className={ "col-md-6" } style={{'display': 'none'}}>
		                    ProblemId:

	                        <input
	                            className="form-control"
	                            placeholder={ highestDocumentID }
	                            name="problemId"
	                            defaultValue={ highestDocumentID }
	                            type="text"
	                            onChange={this.fieldUpdate}
	                        />

		                  </label>

	                        <small className={ 'signupMessage' }></small>
	                    </div>
	                    <div className="form-group container col-md-6">
	                        <input
	                            className="btn btn-primary form-submit col-md-12"
	                            value="Create Document"
	                            type="submit"
	                        />
	                    </div>
	                </form>
	                : <p>{this.state.submitMessage}</p> }

	    		</div>
	    	)
    	} else {
	    	return (
	    		<div>
	    			<div className="nav justify-content-center">
	    				{ authorizationMessage }
	    			</div>
	    		</div>
	    	)
    	}
	}
}

export default withRouter(Documents)