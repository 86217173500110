import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import Autocomplete from 'react-autocomplete'
import {Walkthrough} from './containers/walkthrough'
import {SingleDocument} from './containers/walkthrough/single'

import Script from 'react-load-script';
import axios from 'axios';
/*
import Zendesk from "react-zendesk";
const ZENDESK_KEY = "194e49e3-d98a-403e-ac3b-eda0f08dc118";

const setting = {
  color: {
    theme: "#000"
  },
  launcher: {
    chatLabel: {
      "en-US": "Need Help"
    }
  },
  contactForm: {
    fields: [
      { id: "description", prefill: { "*": "Description" } }
    ]
  },

};
*/

var API = '';

if (window.location.hostname === 'localhost') {
  API = 'http://' + window.location.hostname + ':3001/api';
} else {
  API = 'https://' + window.location.hostname + '/api';
}

if (!String.prototype.includes) {
  String.prototype.includes = function(search, start) {
    'use strict';

    if (search instanceof RegExp) {
      throw TypeError('first argument must not be a RegExp');
    } 
    if (start === undefined) { start = 0; }
    return this.indexOf(search, start) !== -1;
  };
}

function potholesList(stateLocation, countyLocation, sid) {
  return axios.post(API + `/potholes`, {
      data: {
        sid: sid,
        stateName: stateLocation,
        countyName: countyLocation
      }
    })
    .then((response) => {
      // console.log(response)

      // return response.data.result.recordset
      return response.data.result
    })
    .catch(err => 
      console.log(err)
    )
}

function currentLocalitiesPull(sid) {
  return axios.post(API + `/documents-available`, {
      data: {
        sid: sid
      }
    })
    .then((response) => {
      var localities = [];

      for (var key in response.data.result.recordset) {
          // console.log(key)
          localities.push(response.data.result.recordset[key].County)

          if (response.data.result.recordset[key].County === 'ALLCOUNTIES')
            localities.push(response.data.result.recordset[key].State)
      }

      return localities
    })
    .catch(err => 
      console.log(err)
    )
}

class App extends Component {
  constructor(props) {
    super(props);

    // console.log(this.props)

    localStorage.setItem('uwalNameNote', '')

    var projectIssues = ''
    var projectAllIssues = ''
    var projectState = ''
    var projectCounty = ''


    // console.log(issues)

    this.state = {
      pageContent: '',
      projectIssues: projectIssues,
      projectAllIssues: projectAllIssues,
      projectState: projectState,
      projectCounty: projectCounty,
      savedFormattedAddress: '',
      value: '',
      uwalNames: [],
      loading: false,
      authenticated: false, 
      typingTimeout: 0,
      currentLocalities: []
    }

    this.requestTimer = 5

    this.handleScriptLoad = this.handleScriptLoad.bind(this)
    this.fillInAddress = this.fillInAddress.bind(this)
    this.nameSearch = this.nameSearch.bind(this)
  }

  componentDidMount() {
    const { renewSession, login } = this.props.auth

    var pathname = window.location.pathname
    var checkAuth = localStorage.getItem('isLoggedIn')
    var sid = ''

    // console.log(pathname)

    currentLocalitiesPull(sid)
    .then((response) => {
      this.setState({ 
        currentLocalities: response
      })
    })

    if (pathname !== '/auth/callback') {
      if (localStorage.getItem('isLoggedIn') === 'true') {
        renewSession()
        sid = localStorage.getItem('sid')
      } else if (checkAuth === undefined || checkAuth === null || !checkAuth) {
        login()
      }
    } else {
      renewSession()
    }

    // console.log(this.props)

    this.setState({
      searchType: 'address'
    })

    if (this.props.hasOwnProperty('search')) {
      const query = new URLSearchParams(this.props.location.search);
      var queryPulledAddress = query.get('searchString')
      var queryPulledAddressPieces = query.get('searchArray')
      var savedFormattedAddress = ''
      savedFormattedAddress = localStorage.getItem('uwalAddress')
      if (savedFormattedAddress) {
        savedFormattedAddress = savedFormattedAddress.replace(/"/g, '')

        var localPulledState = localStorage.getItem('uwalAddress_state')
        var localPulledCounty = localStorage.getItem('uwalAddress_county')
      
        this.setState({ 
          savedFormattedAddress: savedFormattedAddress,
          projectState: localPulledState,
          projectCounty: localPulledCounty
        })

        var potholes = localStorage.getItem('potholes')

        if (!potholes) {
          potholesList(localPulledState, localPulledCounty, sid)
          .then((response) => {
            this.setState({ 
              // projectPotholes: response
            })
          })
        } else {
            this.setState({ 
              projectPotholes: potholes
            })
        }

      } else if (queryPulledAddress && queryPulledAddressPieces) {  
        queryPulledAddressPieces = JSON.parse(queryPulledAddressPieces)
        var queryPulledState = queryPulledAddressPieces.state['short_name']
        var queryPulledCounty = queryPulledAddressPieces.locality['short_name']

        localStorage.setItem('uwalAddress', queryPulledAddress)
        // projectState: localStorage.setItem('uwalAddress_state', queryPulledState)
        // projectCounty: localStorage.setItem('uwalAddress_county', queryPulledCounty)

        this.setState({ 
          savedFormattedAddress: queryPulledAddress,
          projectState: queryPulledState,
          projectCounty: queryPulledCounty
        })

        // console.log(queryPulledState + queryPulledCounty)

        var potholes = localStorage.getItem('potholes')

        if (!potholes) {
          potholesList(localPulledState, localPulledCounty, sid)
          .then((response) => {
            this.setState({ 
              // projectPotholes: response
            })
          })
        } else {
            this.setState({ 
              projectPotholes: potholes
            })
        }   
      }

      this.setState({
        searchType: 'address'
      })
    }
  }

    handleScriptLoad() {
        /*
        var componentForm = {
          street_number: 'short_name',
          route: 'long_name',
          locality: 'long_name',
          administrative_area_level_1: 'short_name',
          country: 'long_name',
          postal_code: 'short_name'
        };
        */

        // Create the autocomplete object, restricting the search predictions to
        // geographical location types.

        /*global google*/ // To disable any eslint 'google not defined' errors
        this.autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('google_autocomplete'), 
            {
              types: ['geocode'],
              componentRestrictions: {'country': 'us'}
        });

        // Avoid paying for data that you don't need by restricting the set of
        // place fields that are returned to just the address components.
        this.autocomplete.setFields(['address_component']);

        // When the user selects an address from the drop-down, populate the
        // address fields in the form.
        this.autocomplete.addListener('place_changed', this.fillInAddress);

        // Bias the autocomplete object to the user's geographical location,
        // as supplied by the browser's 'navigator.geolocation' object.
        function geolocate() {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position) {
              var geolocation = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              };
              var circle = new google.maps.Circle(
                  {center: geolocation, radius: position.coords.accuracy});
              this.autocomplete.setBounds(circle.getBounds());
            });
          }
        }
    }

    fillInAddress() {
      // Get the place details from the autocomplete object.
      var place = this.autocomplete.getPlace()
      var backupNumber = document.getElementById('google_autocomplete').value
      backupNumber = backupNumber.split(' ')

      // console.log(place.address_components);
      // console.log(typeof place.address_components);

      if (typeof place.address_components != "undefined") {
        /*
        for (var component in componentForm) {
          // document.getElementById(component).value = '';
          document.getElementById(component).disabled = false;
        }

        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.
        for (var i = 0; i < place.address_components.length; i++) {
          var addressType = place.address_components[i].types[0];
          if (componentForm[addressType]) {
            var val = place.address_components[i][componentForm[addressType]];
            document.getElementById(addressType).value = val;
          }
        }
        */

        var formatted_address = '';
        var savedFormattedAddress = '';
        var arrayed_address = {};
        var unusedAddressPieces = ["country", "administrative_area_level_2", "postal_code_suffix", "neighborhood", "neighborhood"];

        for (var i = 0; i < place.address_components.length; i++) {
          // !place.address_components[i].types.includes('country') && 
          // console.log(place.address_components[i].types.includes("country"))

          // console.log(place.address_components)

          if (!unusedAddressPieces.includes(place.address_components[i].types[0]) && i !== place.address_components.length-1) {

            if (place.address_components[i].types.includes('street_number')) {
              arrayed_address.street_number = place.address_components[i];
            }

            if (!arrayed_address.street_number) {
              arrayed_address.street_number = backupNumber
            }

            if (place.address_components[i].types.includes('neighborhood')) {
              arrayed_address.neighborhood = place.address_components[i];
            }

            if (place.address_components[i].types.includes('locality')) {
              formatted_address += '– ';
              arrayed_address.locality = place.address_components[i];
            }

            if (place.address_components[i].types.includes('route')) {
              formatted_address += place.address_components[i].long_name;

              arrayed_address.route = place.address_components[i];
            } else {
              formatted_address += place.address_components[i].short_name;
            }
            
            if (place.address_components[i].types.includes('locality')) {
              formatted_address += ',';             
            }

            if (!place.address_components[i].types.includes('postal_code')) {
              formatted_address += ' ';

              arrayed_address.postal_code = place.address_components[i];
            }
          }

          if (place.address_components[i].types.includes('neighborhood_long')) {
            arrayed_address.neighborhood_long = place.address_components[i];
          }

          if (place.address_components[i].types.includes('administrative_area_level_1')) {
            arrayed_address.state = place.address_components[i];
          }

          if (place.address_components[i].types.includes('administrative_area_level_2')) {
            arrayed_address.county = place.address_components[i]['short_name'].replace(' County', '');
          }

          if (place.address_components[i].types.includes('postal_code')) {
            arrayed_address.postal_code = place.address_components[i];
          }
        }

        document.getElementById('searchString').value = formatted_address;
        var send_formatted_address = formatted_address;

        // console.log(arrayed_address);

        document.getElementById('searchArray').value = JSON.stringify(arrayed_address);
        var send_arrayed_address = JSON.stringify(arrayed_address);


        // console.log(send_formatted_address)
        // console.log(send_arrayed_address)

        var currentLocalities = this.state.currentLocalities

        if (send_formatted_address !== null && send_arrayed_address !== null) {
          document.querySelector('.fa.marker-icon').className = 'fa fa-spinner fa-pulse fa-3x fa-fw marker-icon'
          var sid = localStorage.getItem('sid')

          return axios.get(API + "/lookup?searchString=" + send_formatted_address + "&searchArray=" + send_arrayed_address, {})
            .then((response) => {
              
              // console.log(arrayed_address.state)
              // console.log(arrayed_address.locality)

              // var currentLocalities = ["Philadelphia", "Montgomery", "Bucks", "Chester", "Delaware"] 
              // currentLocalities = ["PA", "NJ"] 

              var queryPulledState = arrayed_address.state['short_name']
              var queryPulledCounty = arrayed_address.county
              var resultsMeta = { exact: 0, similar: 0 }
              var resultsList = ''
              var resultsArrayforChecking = []

              document.querySelector('.fa.marker-icon').className = 'fa fa-map-marker marker-icon'

              // console.log(response)

              // REPLACE - with class
              if (response.data.status === 'found') {
                  document.getElementById('google_autocomplete').value += ''

                  for (var result = 0; result < response.data.result.length; result++) {

                    if(!resultsArrayforChecking.includes(response.data.result[result].address)) {
                      // console.log(resultsArrayforChecking)

                      resultsArrayforChecking[result] = response.data.result[result].address

                      resultsList += '' + response.data.result[result].address + ''

                      // console.log(response)

                      if (response.data.result[result].similarity === 'exact') {
                        resultsMeta.exact = resultsMeta.exact + 1
                      } else {
                        resultsMeta.similar = resultsMeta.similar + 1
                      }
                    }
                  }


                  savedFormattedAddress = JSON.stringify(send_formatted_address).replace(/"/gi, '')

                  localStorage.setItem('uwalAddressNote', 'found')
                  localStorage.setItem('uwalAddress', savedFormattedAddress)

                  //if (arrayed_address.state['short_name'] === 'PA' || currentLocalities.includes(arrayed_address.county)) {

                  // console.log('currentLocalities if Found')
                  // console.log(currentLocalities)
                  // console.log('Check state too')
                  // console.log(arrayed_address.state)                  

                  if (
                    (arrayed_address.county && currentLocalities.includes(arrayed_address.county.toUpperCase())) 
                      || 
                    (arrayed_address.state && currentLocalities.includes(arrayed_address.state['short_name']))
                  ) {
                    localStorage.setItem('uwalAddress_state', arrayed_address.state['short_name'])
                    localStorage.setItem('uwalAddress_county', arrayed_address.county)

                    this.setState({ 
                      savedFormattedAddress: savedFormattedAddress,
                      projectState: queryPulledState,
                      projectCounty: queryPulledCounty
                    })
                  } else {
                    localStorage.setItem('uwalAddress_state', '')
                    localStorage.setItem('uwalAddress_county', '')

                    this.setState({ 
                      savedFormattedAddress: '',
                      projectState: '',
                      projectCounty: ''
                    })
                  }


                  potholesList(queryPulledState, queryPulledCounty, sid) 
                  .then((response) => {
                    // console.log(arrayed_address.state)
                    // console.log(arrayed_address.locality)

                    // console.log(resultsList)

                    localStorage.setItem('potholes', JSON.stringify(response))
                    localStorage.setItem('resultsMeta', JSON.stringify(resultsMeta))
                    localStorage.setItem('resultsList', resultsList)

                    this.setState({ 
                      projectPotholes: JSON.stringify(response)
                    })

                    if (resultsMeta.exact) {
                      this.setState({ 
                        uwalAddressNote: 'found',
                        resultsMeta: resultsMeta,
                        resultsList: resultsList
                      })

                      localStorage.setItem('uwalAddressNote', 'found')
                    } else {
                      this.setState({ 
                        uwalAddressNote: 'notfound',
                        resultsMeta: resultsMeta,
                        resultsList: resultsList
                      })

                      localStorage.setItem('uwalAddressNote', 'notfound')
                    }

                    // console.log(window.location.pathname)

                    if (window.location.pathname !== '/')
                      window.location.href = '/'
                  })

                  // console.log(arrayed_address)
              } else if (response.data.status === 'notfound') {


                  savedFormattedAddress = JSON.stringify(send_formatted_address).replace(/"/gi, '')

                  localStorage.setItem('uwalAddressNote', 'notfound')
                  localStorage.setItem('uwalAddress', savedFormattedAddress)
                  
                  //if (arrayed_address.state['short_name'] === 'PA' || currentLocalities.includes(arrayed_address.county)) {
                    
                  // console.log('currentLocalities NOT Found')
                  // console.log(currentLocalities)
                  // console.log('Check state too')
                  // console.log(arrayed_address)                  

                  if (
                    (arrayed_address.county && currentLocalities.includes(arrayed_address.county.toUpperCase())) 
                      || 
                    (arrayed_address.state && currentLocalities.includes(arrayed_address.state['short_name']))
                  ) {
                    localStorage.setItem('uwalAddress_state', arrayed_address.state['short_name'])
                    localStorage.setItem('uwalAddress_county', arrayed_address.county)

                    this.setState({ 
                      savedFormattedAddress: savedFormattedAddress,
                      projectState: queryPulledState,
                      projectCounty: queryPulledCounty
                    })
                  } else {
                    localStorage.setItem('uwalAddress_state', '')
                    localStorage.setItem('uwalAddress_county', '')

                    this.setState({ 
                      savedFormattedAddress: '',
                      projectState: '',
                      projectCounty: ''
                    })
                  }

                  this.setState({ 
                    uwalAddressNote: 'notfound'
                  })


                  potholesList(queryPulledState, queryPulledCounty, sid) 
                  .then((response) => {
                    // console.log(arrayed_address.state)
                    // console.log(arrayed_address)
                    // console.log(arrayed_address.locality)

                    localStorage.setItem('potholes', JSON.stringify(response))   
                    localStorage.setItem('resultsMeta', JSON.stringify(resultsMeta))
                    localStorage.setItem('uwalAddressNote', 'notfound')
                    localStorage.setItem('resultsList', resultsList)
                 
                    this.setState({ 
                      projectPotholes: JSON.stringify(response), 
                      uwalAddressNote: 'notfound',
                      resultsMeta: resultsMeta
                    })

                    if (window.location.pathname !== '/')
                      window.location.href = '/'
                  })  

                  // console.log(arrayed_address)
              } else {
                document.getElementById('google_autocomplete').value += ''

                localStorage.setItem('uwalAddress_state', '')
                localStorage.setItem('uwalAddress_county', '')
                localStorage.setItem('uwalAddress', '')
              }
            }).then((json) => {
              // return { options: json };
            });
        }
      }
    }

  nameSearch = nameSearch => {
    var _thisComponent = this

    if (_thisComponent.state.typingTimeout) {
       clearTimeout(_thisComponent.state.typingTimeout);
    }

    _thisComponent.state.typingTimeout = setTimeout(() => {
        // console.log(nameSearch)
        var sid = localStorage.getItem('sid')

        return axios.post(API + `/lookup_name` , {
            data: {
              sid: sid,
              searchString: nameSearch
            }
          })
          .then( response => {
            // console.log(response.data.result.recordset)

            if (response.data && response.data.result.recordset.length) {
              _thisComponent.setState({ uwalNames: response.data.result.recordset, loading: false })
            } else {
              _thisComponent.setState({ 
                loading: false 
              })
            }
          })
          .catch(err => 
            console.log(err)
          )
        }, 600)
  }

  setName = (value, state) => {
    // console.log(value)

    var properValue = value.replace('[object Undefined]', '')
    // console.log(properValue)

    localStorage.setItem('uwalNameNote', 'found')

    this.setState({ value: properValue + ' - Found', uwalNames: [] }) 
  }

  changeSearchType = changeSearchType => {
    // console.log(changeSearchType.target.value)

    var _this = this

    this.setState({ 
      searchType: changeSearchType.target.value
    })

    var baseClass = 'col-md-6 uwal-buttons ' + changeSearchType.target.value

    document.querySelectorAll('.uwal-buttons').forEach(function(e) {
      e.className = e.className.replace('active', '')
    })
    document.querySelector('.uwal-buttons.' + changeSearchType.target.value).className = baseClass + ' active'

    if (changeSearchType.target.value === 'address') 
      setTimeout(function() {
        _this.handleScriptLoad()
      }, 100);
  }


  goTo(route) {
    this.props.history.replace(`/${route}`)
  }

  login() {
    this.props.auth.login()
  }

  logout() {
    this.props.auth.logout()
  }

  nothing(e) {
    e.preventDefault()
  }

  clearSlate(e) {
    e.preventDefault()

    localStorage.setItem('potholes', '')
    localStorage.setItem('uwalAddress_state', '')
    localStorage.setItem('uwalAddress_county', '')
    localStorage.setItem('uwalAddress', '')
    localStorage.setItem('uwalAddressNote', '')

    window.location.href = '/'
  }

  renewToken() {
    const { renewSession } = this.props.auth

    renewSession()
  }

  render() {
    const { isAuthenticated } = this.props.auth
    var { savedFormattedAddress } = this.props
    const testAuthenticated = isAuthenticated()

    // console.log(this.props)

    const loggedIn = localStorage.getItem('isLoggedIn')
    var role = ''
        role = localStorage.getItem('role')
    savedFormattedAddress = localStorage.getItem('uwalAddress')
    var uwalAddressNote = this.state.uwalAddressNote
    var uwalNameNote = localStorage.getItem('uwalNameNote')

    /*
    const renderMergedProps = (component, ...rest) => {
      const finalProps = Object.assign({}, ...rest);
      return (
        React.createElement(component, finalProps)
      );
    }

    const PropsRoute = ({ component, ...rest }) => {
      return (
        <Route {...rest} render={routeProps => {
          return renderMergedProps(component, routeProps, rest);
        }}/>
      );
    }
    */

    var pathname = window.location.pathname
    var subPath = pathname.split('/')

    // console.log(pathname)
    // console.log(subPath[1])

    // console.log(window.location.pathname)
    // className="d-flex flex-column flex-md-row align-items-center bg-white border-bottom box-shadow"

    // console.log(testAuthenticated)

    if (testAuthenticated === true) {
      document.body.style.backgroundColor = "#ffffff"
    }

    var $domain = this.props.domain

    // console.log(pathname)
    //console.log($domain)

    return (
      <div>
        { testAuthenticated === true && pathname !== "/auth/callback" ?
        <div>
          { subPath[1] !== "admin" ?
          <Script 
            url="https://maps.googleapis.com/maps/api/js?key=AIzaSyA4HjZ_0bgGjWP5hnAYzBb09ef0cYTnLqU&libraries=places"
            onLoad={this.handleScriptLoad}
          />
          : null }

          <header>       
            <div className="col-md-12">
              <a href="/" className="my-0 mr-md-auto font-weight-normal" onClick={ this.clearSlate }>
                <img className="logo-nav" alt="logo-nav" src="//thetitlebox.com/wp-content/uploads/2019/10/logo.png"/>
              </a>

              <nav className="my-2 my-md-0 mrBack to Walkthrough-md-3 pull-right">            
                { window.location.pathname !== '/' ?
                  <Link to="/" className="p-2"><i className="fa fa-home"></i>Home</Link>
                : null }

                {
                  !loggedIn && (
                      <Link
                        id="qsLoginBtn"
                        to=""
                        className="p-2"
                        onClick={this.login.bind(this)}
                      >
                        Log In
                      </Link>
                    )
                }

                {
                  loggedIn && (
                    <a
                      id="AccountBtn"
                      href="https://thetitlebox.com/my-account/edit-account/"
                      className="p-2"
                    >
                      My Account 
                    </a> 
                  )
                }

                {
                  loggedIn && (
                    <Link
                      id="qsLogoutBtn"
                      to=""
                      className="p-2"
                      onClick={this.logout.bind(this)}
                    >
                      Log Out
                    </Link>
                  )
                }

                {
                  role === 'administrator' && $domain.indexOf('app.thetitlebox') < 1 && (
                      <Link to="/admin/editor" className="p-2">Admin</Link>
                    )
                }
              </nav>
            </div>



            <div className="col-md-12 search-area">
              { subPath[1] !== "admin" ?
              <div onChange={this.changeSearchType} className="btn-group btn-group-toggle col-md-6 d-block mx-auto text-center" data-toggle="buttons">
                <label className="col-sm-6 col-md-6 uwal-buttons address active">Search by Address  <input type="radio" value="address" name="searchType"/></label>
                <label className="col-sm-6 col-md-6 uwal-buttons name">Search by Name     <input type="radio" value="name" name="searchType"/></label>
              </div>
              : null }

              { this.state.searchType === "address" && subPath[1] !== "admin" ? 
              <form className="sub-form col-lg-12" id="home-form" onSubmit={ this.handleUWAL }>

                <div className="input-group col-6 d-block mx-auto"> 
                  <i className="fa fa-map-marker marker-icon"></i>
                  <input id="google_autocomplete" placeholder="Enter Address" onFocus={this.geolocate} onKeyPress={this.handleKeyPress} type="text" className={"form-control input-lg col-md-12 shadow " + uwalAddressNote } defaultValue={ savedFormattedAddress } autoComplete="off" />

                  <input type="hidden" name="searchString" id="searchString" />
                  <input type="hidden" name="searchArray" id="searchArray" />

                  <span className="input-group-btn">
                      <button type="submit" className="btn search-button-address" onClick={ this.nothing }>Search!</button>
                  </span>

                </div>

              </form>
              : null }

              { this.state.searchType === "name" ? 
              <form className="col-md-12 sub-form" id="name-form" onSubmit={ this.nothing }>
                <div className="input-group col-6 d-block mx-auto">
                  <i className="fa fa-list"></i> 
                  <Autocomplete
                    value={this.state.value}
                    inputProps={{ id: 'nameAutocomplete', className: 'form-control input-lg ' + uwalNameNote, placeholder: "Enter Name" }}
                    items={this.state.uwalNames}
                    getItemValue={(item) => toString(item.NameId) + item.Name}
                    onSelect={this.setName}
                    onChange={(event, value) => {
                      this.setState({ value, loading: true })

                      var _this = this

                      if (value.length > 2) {
                        setTimeout( function() {
                          _this.nameSearch(value, (items) => {
                            this.setState({ uwalNames: items, loading: false })
                          })
                        }, 100)
                      } else {
                        this.setState({ uwalNames: [], loading: false })
                      }
                    }}
                    renderItem={(item, isHighlighted) => (
                      item && item.Name && item.NameId && toString(item.NameId) ?
                        <div key={toString(item.NameId) + item.Name} className={toString(item.NameId) + item.Name} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                          {item.Name}
                        </div>
                      :
                        null                 
                    )}
                    renderMenu={(items, value) => (
                      <div className={value ? 'menu starting' : 'menu'}>
                        {value === '' ? (
                          <div className="item"></div>
                        ) : this.state.loading ? (
                          <div className="item">Loading...</div>
                        ) : items.length === 0 && value.includes('Found') ? (
                          <div className="item">Start a new search.</div>
                        ) : items.length === 0 ? (
                          <div className="item">No matches for {value}</div>
                        ) : items}
                      </div>
                    )}
                  />

                  <span className="input-group-btn">
                      <button type="submit" className="btn search-button-name">Search!</button>
                  </span>
                </div>
              </form>
              : null }
            </div>

            <div className="latest-update-button">
              <a href="/latestupdates">
                LATEST UPDATES
              </a>
            </div>

          </header>

          { pathname === "/" ?
            <Walkthrough masterissues={this.props.masterissues} projectState={this.state.projectState} projectCounty={this.state.projectCounty} projectPotholes={this.state.projectPotholes} uwalAddressNote={this.state.uwalAddressNote} resultsMeta={this.state.resultsMeta} resultsList={this.state.resultsList} auth={this.props.auth}  />
          : null }

          { (subPath[1] && subPath[1] === "documents") || (subPath[1] && subPath[1] === "admin") || pathname === "/latestupdates" ?
            this.props.children
          : null }

        </div>
      : null }
      </div>
    )
  }
}

export default App;