import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import Autocomplete from 'react-autocomplete'
import Script from 'react-load-script';

import 'react-select/dist/react-select.css';
import 'font-awesome/css/font-awesome.css'

var API = '';

if (window.location.hostname === 'localhost') {
  API = 'http://' + window.location.hostname + ':3001/api';
} else {
  API = 'https://' + window.location.hostname + '/api';
}

class Documents extends Component {
	constructor(props) {
		super(props)


	    this.state = {
	      pageContent: '',
	      savedFormattedAddress: '',
	      value: '',
	      uwalNames: [],
	      loading: false    
	    }

	    this.handleSubmit = this.handleSubmit.bind(this)
	    this.handleUWALaddress = this.handleUWALaddress.bind(this)
	    this.handleUWALname = this.handleUWALname.bind(this)
    	this.fillInAddress = this.fillInAddress.bind(this)
    	this.handleScriptLoad = this.handleScriptLoad.bind(this)
	}

	  nameSearch = nameSearch => {
	    var _thisComponent = this
	    var sid = localStorage.getItem('sid')

	    if (_thisComponent.state.typingTimeout) {
	       clearTimeout(_thisComponent.state.typingTimeout);
	    }

	    _thisComponent.state.typingTimeout = setTimeout(() => {
	        // console.log(nameSearch)

	        return axios.post(API + `/lookup_name`, {
	            data: {
	              sid: sid,
	              searchString: nameSearch
	            }
	          })
	          .then( response => {
	            // console.log(response.data.result.recordset)

	            if (response.data && response.data.result.recordset.length) {
	              _thisComponent.setState({ 
	              	uwalNames: response.data.result.recordset, 
	              	loading: false 
	              })
	            } else {
	            	// console.log(response.data)

	            	_thisComponent.setState({ 
	            		loading: false,
            			uwalActionName: "insert" 
	            	})

	    			document.getElementById('nameAutocomplete').style.color = '#000'
				    document.getElementById('nameActiontobeset').innerHTML = 'Add to the UWAL'
				    document.getElementById('nameActiontobeset').disabled = false
	            }
	          })
	          .catch(err => 
	            console.log(err)
	          )
	        }, 600)
	  }

	  setName = (value, state) => {
	    // console.log(value)

	    var properValue = value.replace('[object Undefined]', '')
	    // console.log(properValue)

	    document.getElementById('nameAutocomplete').style.background = '#fdedee'
	    document.getElementById('nameAutocomplete').style.color = '#000'
	    document.getElementById('nameAutocomplete').style.border = '1px solid #f53131'
	    document.getElementById('nameAutocomplete').value += ' - Found'

	    document.getElementById('nameActiontobeset').innerHTML = 'Remove from the UWAL'
	    document.getElementById('nameActiontobeset').disabled = false

	    this.setState({ 
	    	value: properValue + ' - Found', 
	    	uwalName: properValue,
	    	uwalNames: [],
            uwalActionName: "delete"
	    }) 
	  }

	handleScriptLoad() {
		/*
	    var componentForm = {
	      street_number: 'short_name',
	      route: 'long_name',
	      locality: 'long_name',
	      administrative_area_level_1: 'short_name',
	      country: 'long_name',
	      postal_code: 'short_name'
	    };
	    */

	    // var searchForm = document.getElementById('home-form');

	    // Create the autocomplete object, restricting the search predictions to
	    // geographical location types.

	    /*global google*/ // To disable any eslint 'google not defined' errors
	    this.autocomplete = new google.maps.places.Autocomplete(
	        document.getElementById('google_autocomplete'), 
	        {
	          types: ['geocode'],
	          componentRestrictions: {'country': 'us'}
	    });

	    // Avoid paying for data that you don't need by restricting the set of
	    // place fields that are returned to just the address components.
	    this.autocomplete.setFields(['address_component']);

	    // When the user selects an address from the drop-down, populate the
	    // address fields in the form.
	    this.autocomplete.addListener('place_changed', this.fillInAddress);

	    // Bias the autocomplete object to the user's geographical location,
	    // as supplied by the browser's 'navigator.geolocation' object.
	    function geolocate() {
	      if (navigator.geolocation) {
	        navigator.geolocation.getCurrentPosition(function(position) {
	          var geolocation = {
	            lat: position.coords.latitude,
	            lng: position.coords.longitude
	          };
	          var circle = new google.maps.Circle(
	              {center: geolocation, radius: position.coords.accuracy});
	          this.autocomplete.setBounds(circle.getBounds());
	        });
	      }
	    }
	}

	fillInAddress() {
      // Get the place details from the autocomplete object.
      var place = this.autocomplete.getPlace();

      // console.log(place.address_components);
      // console.log(typeof place.address_components);

      if (typeof place.address_components != "undefined") {
        /*
        for (var component in componentForm) {
          // document.getElementById(component).value = '';
          document.getElementById(component).disabled = false;
        }

        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.
        for (var i = 0; i < place.address_components.length; i++) {
          var addressType = place.address_components[i].types[0];
          if (componentForm[addressType]) {
            var val = place.address_components[i][componentForm[addressType]];
            document.getElementById(addressType).value = val;
          }
        }
        */

        // console.log(place)

        var formatted_address = '';
        var arrayed_address = {};
        var unusedAddressPieces = ["country", "administrative_area_level_2", "postal_code_suffix", "neighborhood", "neighborhood"];

        for (var i = 0; i < place.address_components.length; i++) {
          // !place.address_components[i].types.includes('country') && 
          // console.log(place.address_components[i].types.includes("country"))

          // console.log(place.address_components)

          if (!unusedAddressPieces.includes(place.address_components[i].types[0]) && i !== place.address_components.length-1) {

            if (place.address_components[i].types.includes('street_number')) {
              arrayed_address.street_number = place.address_components[i];
            }

            if (place.address_components[i].types.includes('neighborhood')) {
              arrayed_address.neighborhood = place.address_components[i];
            }

            if (place.address_components[i].types.includes('locality')) {
              formatted_address += '– ';
              arrayed_address.locality = place.address_components[i];
            }

            if (place.address_components[i].types.includes('route')) {
              formatted_address += place.address_components[i].long_name;

              arrayed_address.route = place.address_components[i];
            } else {
              formatted_address += place.address_components[i].short_name;
            }
            
            if (place.address_components[i].types.includes('locality')) {
              formatted_address += ',';             
            }

            if (!place.address_components[i].types.includes('postal_code')) {
              formatted_address += ' ';

              arrayed_address.postal_code = place.address_components[i];
            }
          }

          if (place.address_components[i].types.includes('neighborhood_long')) {
            arrayed_address.neighborhood_long = place.address_components[i];
          }

          if (place.address_components[i].types.includes('administrative_area_level_1')) {
            arrayed_address.state = place.address_components[i];
          }

          if (place.address_components[i].types.includes('administrative_area_level_2')) {
            arrayed_address.county = place.address_components[i]['short_name'].replace(' County', '');
          }

          if (place.address_components[i].types.includes('postal_code')) {
            arrayed_address.postal_code = place.address_components[i];
          }
        }

        document.getElementById('searchString').value = formatted_address;
        var send_formatted_address = formatted_address;

        // console.log(arrayed_address);

        document.getElementById('searchArray').value = JSON.stringify(arrayed_address);
        var send_arrayed_address = JSON.stringify(arrayed_address);


        // console.log(arrayed_address)
        // console.log(arrayed_address['street_number'])

        if (arrayed_address['street_number'] && send_formatted_address !== null && send_arrayed_address !== null) {
          return axios.get(API + "/lookup?searchString=" + send_formatted_address + "&searchArray=" + send_arrayed_address, {})
            .then((response) => {
              
              // console.log(response)
              // console.log(arrayed_address.state)
              // console.log(arrayed_address.locality)

              // var currentLocalities = ["Philadelphia", "Montgomery", "Bucks", "Chester", "Delaware"] 

              // var queryPulledState = arrayed_address.state['short_name']
              // var queryPulledCounty = arrayed_address.county

              // REPLACE - with class
              if (response.data.status === 'found') {
                  document.getElementById('google_autocomplete').style.background = '#fdedee'
                  document.getElementById('google_autocomplete').style.color = '#000'
                  document.getElementById('google_autocomplete').style.border = '1px solid #f53131'
                  document.getElementById('google_autocomplete').value += ' - Already Found on the UWAL'

                  document.getElementById('addressActiontobeset').innerHTML = 'Remove from UWAL'
                  document.getElementById('addressActiontobeset').disabled = false

                  // console.log(arrayed_address.state)
                  // console.log(arrayed_address.locality)

                  var savedFormattedAddress = JSON.stringify(send_formatted_address).replace(/"/gi, '')

                  this.setState({ 
                    savedFormattedAddress: savedFormattedAddress,
                    savedArrayedAddress: send_arrayed_address,
                    uwalActionAddress: "delete"
                  })

              } else {
                  document.getElementById('google_autocomplete').style.background = '#edfdf2'
                  document.getElementById('google_autocomplete').style.color = '#000'
                  document.getElementById('google_autocomplete').style.border = '1px solid #31f566'
                  document.getElementById('google_autocomplete').value += ' - Not Already on the UWAL'

                  // console.log(arrayed_address.state)
                  // console.log(arrayed_address)
                  // console.log(arrayed_address.locality)

                  document.getElementById('addressActiontobeset').innerHTML = 'Add to the UWAL'
                  document.getElementById('addressActiontobeset').disabled = false

                  var savedFormattedAddress = JSON.stringify(send_formatted_address).replace(/"/gi, '')
                  // console.log(savedFormattedAddress)
                  // var countyAvailable = currentLocalities.includes(arrayed_address.county)

                  this.setState({ 
                    savedFormattedAddress: savedFormattedAddress,
                    savedArrayedAddress: send_arrayed_address,
                    uwalActionAddress: "insert"
                  })

                  // if (!currentLocalities.includes(countyAvailable)) {
                    // countyAvailable = "Broader PA"
                  // }
              }
            }).then((json) => {
              // return { options: json };
            });
        }
      }
    }

	componentDidMount() {
	    var _thisComponent = this

	    document.title = "Titlebox - Start Title Walkthrough"

	    const { login } = _thisComponent.props.auth;

    // login()

	    var $checkAuth = localStorage.getItem('isLoggedIn')

	    if ($checkAuth === undefined || $checkAuth === null) {
	      login()
	    } else if ($checkAuth) {
	      var $role = localStorage.getItem('role')
	      // var $subscriptions = localStorage.getItem('subscriptions')

	      this.setState({
	      	role: $role
	      })

	      // console.log($role)
	      // console.log($subscriptions)

	      if (!$role)
	        window.location.href = '//thetitlebox.com'
	    }

    	// console.log( $checkAuth )


	    const { renewSession } = _thisComponent.props.auth;

	    if (localStorage.getItem('isLoggedIn') === 'true') {
	      renewSession();
	    }
	}

    fieldUpdate = (fieldProperty) => {
    	var $name =	 fieldProperty.target.name
    	var $value = fieldProperty.target.value

        this.setState({ 
        	[$name]: $value 
        })

        // console.log($name)
        // console.log($value)
    }

	handleSubmit(event) {
	    event.preventDefault()

	    // const data = new FormData(event.target)

	    axios.post(API + `/document-creation`, {
	        data: this.state
	      })
	      .then((response) => {
	      	this.setState({
	      		submitMessage: 'New document created, click over to the editor to add content.'
	      	})

	      	this.forceUpdate()
	      }).catch((response) => {
	      	this.setState({
	      		submitMessage: 'Error creating the document. Refresh and try again.'
	      	})
	        // console.log(response)
	      })

	    // console.log(this.state)
    }



	handleUWALaddress(event) {
	    event.preventDefault()
	    var sid = localStorage.getItem('sid')

	    // console.log(this.state.savedFormattedAddress)

	    if (this.state.savedFormattedAddress) {
		    axios.post(API + `/change_UWAL`, {
		        data: {
		        	sid: sid,
		        	stringAddress: this.state.savedFormattedAddress,
		        	arrayAddress: this.state.savedArrayedAddress,
		        	type: 'address',
		        	action: this.state.uwalActionAddress
		        }
		      })
		      .then((response) => {
		      	// console.log(response)

		      	if (response.data.result === 'success') {
		      		document.getElementById('google_autocomplete').value = ''
					document.getElementById('google_autocomplete').removeAttribute('style')
		      		document.getElementById('addressActiontobeset').innerHTML = 'Updated'

		      		setTimeout(function() {
	                	document.getElementById('addressActiontobeset').innerHTML = 'Enter an Address'
						document.getElementById('addressActiontobeset').disabled = true
					}, 1000)
				}
		      }).catch((response) => {
		      	// if (response)
		      		// console.log(response)
		      })
	    }
	}

	handleUWALname(event) {
	    event.preventDefault()
	    var sid = localStorage.getItem('sid')

	    // console.log(event.target)
	    // console.log(this.state)

	    if (this.state.UWALname || this.state.value) {
		    axios.post(API + `/change_UWAL`, {
		        data: {
		        	sid: sid,
		        	stringName: this.state.uwalName,
		        	value: this.state.value,
		        	type: 'name',
		        	action: this.state.uwalActionName
		        }
		      })
		      .then((response) => {
		      	if (response.data.result === 'success') {
		      		this.setState({value: ''})
		      		document.getElementById('nameAutocomplete').removeAttribute('style')
		      		document.getElementById('nameActiontobeset').innerHTML = 'Updated'

		      		setTimeout(function() {
	                	document.getElementById('nameActiontobeset').innerHTML = 'Enter a Name'
						document.getElementById('nameActiontobeset').disabled = true
					}, 1000)
		      	}
		      }).catch((response) => {
		      	// if (response)
		      		// console.log(response)
		      })
	    }
	}

	render() {
		var allInputProperties = ['DocumentName','ActualDocumentName','DisplayDocumentName']
		// var allSelectProperties = ['StateId','CountyId']

	    var { authorizationMessage } = this.state

        var adminLinks = ['editor','new document','UWAL']

		if (this.state.role === 'administrator') { 
		    return (
		    	<div className="container editor-layout">
		    		<ul className="nav justify-content-center text-center">
					  {Object.entries(adminLinks).map(([key, value]) =>
		              	<li className={ 'nav-link col-md-3 ' + key } key={ key }><a href={ '/admin/' + value.replace(' ', '-') }>{ value }</a></li>
					  )}
					</ul>

				    <Script 
			          url="https://maps.googleapis.com/maps/api/js?key=AIzaSyA4HjZ_0bgGjWP5hnAYzBb09ef0cYTnLqU&libraries=places"
			          onLoad={this.handleScriptLoad}
			        />

			          <form className="sub-form col-md-12 pull-right" id="home-form" onSubmit={ this.handleUWALaddress }>

			            <div className="input-group col-md-8 pull-left"> 
			              <input id="google_autocomplete" placeholder="Enter Address" onFocus={this.geolocate} type="text" className="form-control input-lg" autoComplete="off" />

			              <input type="hidden" name="searchString" id="searchString" />
			              <input type="hidden" name="searchArray" id="searchArray" />

			            </div>

			            <span className="input-group-btn col-md-4 pull-right">
			            	<button type="submit" className="btn btn-primary" disabled={true} id="addressActiontobeset">Enter an Address</button>
			            </span>
			          </form>

			          <form className="sub-form col-md-12" id="name-form" onSubmit={ this.handleUWALname }>

			            <div className="input-group col-md-8 pull-left"> 
		                    <Autocomplete
		                      value={this.state.value}
		                      inputProps={{ id: 'nameAutocomplete', className: 'form-control input-lg', placeholder: "Enter Name" }}
		                      items={this.state.uwalNames}
		                      getItemValue={(item) => toString(item.NameId) + item.Name}
		                      onSelect={this.setName}
		                      onChange={(event, value) => {
		                        this.setState({ value, loading: true })

		                        var _this = this

		                        if (value.length > 2) {
		                          setTimeout( function() {
		                            _this.nameSearch(value, (items) => {
		                              this.setState({ uwalNames: items, loading: false })
		                            })
		                          }, 100)
		                        } else {
		                          this.setState({ uwalNames: [], loading: false })
		                        }
		                      }}
		                      renderItem={(item, isHighlighted) => (
		                        item && item.Name && item.NameId && toString(item.NameId) ?
		                          <div key={toString(item.NameId) + item.Name} className={toString(item.NameId) + item.Name} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
		                            {item.Name}
		                          </div>
		                        :
		                          null                 
		                      )}
		                      renderMenu={(items, value) => (
		                        <div className={value ? 'menu starting' : 'menu'}>
		                          {value === '' ? (
		                            <div className="item"></div>
		                          ) : this.state.loading ? (
		                            <div className="item">Loading...</div>
		                          ) : items.length === 0 && value.includes('Found') ? (
		                            <div className="item">Start a new search.</div>
		                          ) : items.length === 0 ? (
		                            <div className="item">No matches for {value}</div>
		                          ) : items}
		                        </div>
		                      )}
		                    />



			            </div>

			              <span className="input-group-btn col-md-4 pull-right">
			                  <button type="submit" className="btn btn-primary" disabled={true} id="nameActiontobeset">Enter a Name</button>
			              </span>
			          </form>

	    		</div>
	    	)
    	} else {
	    	return (
	    		<div>
	    			<div className="nav justify-content-center">
	    				{ authorizationMessage }
	    			</div>
	    		</div>
	    	)
    	}
	}
}

export default withRouter(Documents)