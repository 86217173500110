// import React from 'react';
// import { render } from 'react-dom';
// import { Provider } from 'react-redux';
// import { Router } from 'react-router-dom'
// import store from './store';
// import App from './containers/app';
// import HttpsRedirect from 'react-https-redirect';
// import history from './history';
// import './js/bootstrap.min.js';

import 'sanitize.css/sanitize.css';
import './index.css';


import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import "core-js/stable";

import ReactDOM from 'react-dom';
import { makeMainRoutes } from './routes';

const routes = makeMainRoutes();

ReactDOM.render(
  routes,
  document.getElementById('root')
);

