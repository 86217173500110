import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import Walkthrough from './containers/walkthrough';
import WalkthroughFinish from './containers/walkthrough/finish';
import SingleDocument from './containers/walkthrough/single';
import axios from 'axios';

import Documents from './containers/admin/new-document';
// import MultipleEdit from './containers/admin/multipleedit';
import Editor from './containers/admin/editor';
// import Reset from './containers/admin/reset';
import UWAL from './containers/admin/uwal';
// import Potholes from './containers/admin/potholes';
// import Admin from './containers/admin';
// import SearchArea from './containers/searcharea';

import GoogleAnalytics from 'react-ga';

import App from './App';
// import Profile from './Profile';

import Home from './containers/home';
import LatestUpdates from './containers/latestupdates';
import Callback from './containers/auth/callback';
import Auth from './containers/auth';
import history from './history';

const auth = new Auth()

let $environment = process.env.NODE_ENV
let $domain = window.location.href

axios.interceptors.request.use( async(config) => {
  let accessToken = process.env.REACT_APP_API_KEYEXTENDED

  if (accessToken) {
    config.headers['authorization'] = `${accessToken}`
    config.headers['Content-Type'] = 'application/json'
    config.headers['Cache-Control'] = 'cache'

    return config
  }

}, function (error) {
  return Promise.reject(error)
})

const handleAuthentication = ({location}) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication()

    // console.log(auth)
  }
}

var masterIssueList = [
  {value: "BANKRUPTCY", label: "BANKRUPTCY"},
  {value: "JUDGMENTS", label: "JUDGMENTS"},
  {value: "FORECLOSURE", label: "FORECLOSURE"},
  {value: "CHAIN OF TITLE", label: "CHAIN OF TITLE"},
  {value: "MORTGAGES", label: "MORTGAGES"},
  {value: "ASSOCIATIONS AND COMMUNITIES", label: "ASSOCIATIONS AND COMMUNITIES"},
  {value: "ENTITIES", label: "ENTITIES"},
  {value: "REAL ESTATE TAXES", label: "REAL ESTATE TAXES"},
  {value: "ESTATES", label: "ESTATES"},
  {value: "MARRIAGE AND DIVORCE", label: "MARRIAGE AND DIVORCE"}
]

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return (
    React.createElement(component, finalProps)
  );
}

const PropsRoute = ({ component, ...rest }) => {
  return (
    <Route {...rest} render={routeProps => {
      return renderMergedProps(component, routeProps, rest);
    }}/>
  );
}

if ($environment !== 'development') {
  GoogleAnalytics.initialize('UA-122705774-1')
  GoogleAnalytics.pageview(window.location.pathname + window.location.search)
} else {
  console.log($domain)
  console.log($domain.indexOf('app.thetitlebox'))
  console.log($environment)
}

export const makeMainRoutes = () => {

  

  return (
      <Router history={history}>
        <>
          <Route path="/auth/callback" render={(props) => {
            handleAuthentication(props);
            return <Callback {...props} /> 
          }}/>

          <App auth={auth} masterissues={ masterIssueList } domain={ $domain }>

            <div className="workspace">
              <main>
                {$domain.indexOf('app.thetitlebox') < 1 ?
                  <Switch>
                    <PropsRoute path="/admin/new-document"            component={ Documents }     auth={auth} />
                    <PropsRoute path="/admin/editor/:documenttoedit"  component={ Editor }        auth={auth} />
                    <PropsRoute path="/admin/editor"                  component={ Editor }        auth={auth} />
                    <PropsRoute path="/admin/uwal"                    component={ UWAL }          auth={auth} />

                    <PropsRoute path="/documents/:state/:county/:issue/:subissue/:id/:section"  component={ SingleDocument } masterissues={ masterIssueList } auth={auth} />
                    <PropsRoute path="/documents/:state/:county/:issue/:subissue/:id"           component={ SingleDocument } masterissues={ masterIssueList } auth={auth} />
                    <PropsRoute path="/documents/:state/:county/:issue/:subissue"               component={ SingleDocument } masterissues={ masterIssueList } auth={auth} />
                    <PropsRoute path="/documents/:state/:county/:issue"                         component={ SingleDocument } masterissues={ masterIssueList } auth={auth} />
                    <PropsRoute path="/documents/:state/:county"                                component={ SingleDocument } masterissues={ masterIssueList } auth={auth} />
                    <PropsRoute path="/documents/:state"                                        component={ SingleDocument } masterissues={ masterIssueList } auth={auth} />
                  
                    <PropsRoute path="/latestupdates"  component={ LatestUpdates }       masterissues={ masterIssueList }  auth={auth} />
                    
                    <PropsRoute path="/"               component={ Walkthrough }         masterissues={ masterIssueList }  auth={auth} />

                  </Switch>
                : 
                  <Switch>
                    <Route path="/signin" render={(props) => <Home auth={auth} {...props} />} />

                    <PropsRoute path="/documents/:state/:county/:issue/:subissue/:id/:section"  component={ SingleDocument } masterissues={ masterIssueList } auth={auth} />
                    <PropsRoute path="/documents/:state/:county/:issue/:subissue/:id"           component={ SingleDocument } masterissues={ masterIssueList } auth={auth} />
                    <PropsRoute path="/documents/:state/:county/:issue/:subissue"               component={ SingleDocument } masterissues={ masterIssueList } auth={auth} />
                    <PropsRoute path="/documents/:state/:county/:issue"                         component={ SingleDocument } masterissues={ masterIssueList } auth={auth} />
                    <PropsRoute path="/documents/:state/:county"                                component={ SingleDocument } masterissues={ masterIssueList } auth={auth} />
                    <PropsRoute path="/documents/:state"                                        component={ SingleDocument } masterissues={ masterIssueList } auth={auth} />
                  
                    <PropsRoute path="/latestupdates"  component={ LatestUpdates }       masterissues={ masterIssueList }  auth={auth} />

                    <Route path="/walkthrough/finish"  component={ WalkthroughFinish }   masterissues={ masterIssueList }  auth={auth} />
                    <PropsRoute path="/"               component={ Walkthrough }         masterissues={ masterIssueList }  auth={auth} />
                  </Switch>
                }
              </main>
            </div>
          </App>
        </>
      </Router>
  );
}
