import history from '../../history';
import auth0 from 'auth0-js';
import CryptoAES from 'crypto-js/aes';
import { AUTH_CONFIG } from './auth0-variables';

export default class Auth {
  accessToken;
  idToken;
  expiresAt;
  userProfile;
  tokenRenewalTimeout;

  auth0 = new auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientID,
    clientSecret: AUTH_CONFIG.clientSecret,
    redirectUri: AUTH_CONFIG.callbackUrl,
    responseType: 'token id_token',
    scope: 'openid profile'
  });

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.renewSession = this.renewSession.bind(this);
    // this.getProfile = this.getProfile.bind(this);
    this.scheduleRenewal();
  }

  login() {
    this.auth0.authorize();
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);

        history.replace('/')
      } else if (err) {
        history.replace('/')
        // console.log(err);
        // alert(`Error: ${err.error}. Check the console for further details.`);
      }
    });
  }

  getAccessToken() {
    return this.accessToken;
  }

  getIdToken() {
    return this.idToken;
  }

  setSession(authResult) {
    // Set isLoggedIn flag in localStorage
    var role = authResult.idTokenPayload['https://formfunction.io/role']
    var subscriptionsJSON = authResult.idTokenPayload['https://formfunction.io/subscriptions']
    var orderID = authResult.idTokenPayload['https://formfunction.io/order_key']

    // Set the time that the access token will expire at
    let expiresAt = (authResult.expiresIn * 10080) + new Date().getTime()
    this.accessToken = authResult.accessToken
    this.idToken = authResult.idToken
    this.expiresAt = expiresAt

    var cipherUserData = {
      "role" : role,
      "subscriptions" : subscriptionsJSON[0],
      "expiresAt" : expiresAt,
      "idToken" : authResult.idToken,      
    }
    cipherUserData = JSON.stringify(cipherUserData)
    cipherUserData = CryptoAES.encrypt(cipherUserData, process.env.REACT_APP_API_KEYSIMPLE)

    localStorage.setItem('isLoggedIn', 'true')
    localStorage.setItem('sid', cipherUserData)

    localStorage.setItem('role', role)
    localStorage.setItem('subscriptions', subscriptionsJSON[0])

    role = localStorage.getItem('role')
    var subscriptions = localStorage.getItem('subscriptions')


    // navigate to the home route
    // console.log(history.location.pathname)
    this.scheduleRenewal()

    /* if is domain!
    if (orderID && orderID.includes('wc')) {
      console.log('redirect')
      console.log('https://app.thetitlebox.com' + history.location.pathname)
      window.location.href = 'https://app.thetitlebox.com' + history.location.pathname
    }
    */

    if (!role || role === 'undefined') {
      this.logout('redirect')
    } else if (history.location.pathname === '/auth/callback') {
      history.replace('/')
    } else {
      history.replace(history.location.pathname)
    }
  }

  renewSession() {
    this.auth0.checkSession({}, (err, authResult) => {
       if (authResult && authResult.accessToken && authResult.idToken) {
         this.setSession(authResult)
       } else if (err) {
         this.logout();
         // console.log(err);
         // alert(`Could not get a new token (${err.error}: ${err.error_description}).`);
       }
    })
  }

  /*
  getProfile(cb) {
    if (this.accessToken) {
      this.auth0.client.userInfo(this.accessToken, (err, profile) => {
        if (profile) {
          this.userProfile = profile

          request.post({
              url: 'https://formfunctionio.auth0.com/oauth/token',
              form: {
                grant_type : 'client_credentials',
                client_id : AUTH_CONFIG.clientID,
                client_secret : AUTH_CONFIG.clientSecret,
                audience : 'https://formfunctionio.auth0.com/api/v2/'
              }
            },
            (err, response, body) => {
              var $access_token = body
              $access_token = JSON.parse($access_token)
              $access_token = $access_token.access_token

              axios.get('https://formfunctionio.auth0.com/api/v2/users/' + profile.sub, {
                    headers: { 
                      authorization: 'Bearer ' + $access_token 
                    }
                  })
                .then(function(response) {
                  // console.log(response)

                  profile.app_metadata = response.data.app_metadata

                  cb(err, profile);
                })
                .catch(function(err) {
                  console.log(err)
                })
            }
          )

          
          cb(err, profile)

          axios.get('https://formfunctionio.auth0.com/api/v2/users/' + profile.sub, {
                headers: { 
                  authorization: 'Bearer ' + this.accessToken 
                }
              })
            .then(function(response) {
              // console.log(response)

              profile.app_metadata = response.data.app_metadata

              cb(err, profile);
            })
            .catch(function(err) {
              console.log(err)
            })
         
        }
        
      })
    }
    } else {
      history.replace('/signin');
    } 
  }
  */

  logout(redirect) {
    // Remove tokens and expiry time
    this.accessToken = null;
    this.idToken = null;
    this.expiresAt = 0;

    // Remove user profile
    this.userProfile = null;

    // var redirect = redirect

    // Clear token renewal
    clearTimeout(this.tokenRenewalTimeout)

    // Remove isLoggedIn flag from localStorage
    localStorage.clear()

    if (redirect) {
      this.auth0.logout({
        returnTo: "https://thetitlebox.com/checkout"
      })
    } else {
      this.auth0.logout({
        // returnTo: "https://thetitlebox.com/newfeatturesandcontent"
        returnTo: window.location.origin
      })
    }
    
    /*
    if (redirect) {
      // navigate to the home route
      history.replace('//localhost')
    } else {
      history.replace('/')
    }
    */
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = this.expiresAt;
    return new Date().getTime() < expiresAt;
  }

  scheduleRenewal() {
    let expiresAt = this.expiresAt;
    const timeout = expiresAt - Date.now();
    if (timeout > 0) {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewSession();
      }, timeout);
    }
  }

  getExpiryDate() {
    return JSON.stringify(new Date(this.expiresAt));
  }
}
