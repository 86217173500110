import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
//import { createBrowserHistory } from "history";

//const history = createBrowserHistory();

var userSelectedContentOptions = '';

var projectIssuesObject = {}

var issues = {
	'bankruptcy': "Bankruptcy",
	'judgments': "Judgments",
	'foreclosure': "Foreclosure",
	'chain-of-title': "Chain of Title",
	'mortgage': "Mortgage",
	'associations': "Associations",
	'entities': "Entities",
	'real-estate-taxes': "Real Estate Taxes",
	'estate': "Estate",
	'marital-and-divorce': "Marriage and Divorce"
};

class WalkthroughFinish extends Component {
	constructor(props) {
		super(props);

	   	var projectIssues = ''
	   	var projectAllIssues = ''
	   	var projectState = ''
	   	var projectCounty = ''

	   	userSelectedContentOptions = localStorage.getItem('userSelectedContentOptions')

		if (userSelectedContentOptions) {
		    userSelectedContentOptions = JSON.parse(userSelectedContentOptions)

		    // This is not great, it creates a new object, checking the slected ones first, then adds to that new object after, then sets the state. 
		    // Has to be better but well, this does work.
		    Object.entries(issues).map(([key, value]) => {
		    	if (userSelectedContentOptions.issues.includes(key)) {
		    		//console.log(key)
		    		var objectName = key + ' selected'

		    		projectIssuesObject[objectName] = value
		    	} else {
		    		projectIssuesObject[key] = value
		    	}

		    	return ''
		    }, 
			    projectIssues = userSelectedContentOptions.issues,
			    projectAllIssues = projectIssuesObject,
			    projectState = userSelectedContentOptions.state.toLowerCase(),
			    projectCounty = userSelectedContentOptions.county.toLowerCase()

				//userSelectedContentOptions.issues.forEach( function(issue) {
					//var objectName = issue + ' selected'
				
					//projectIssuesObject[objectName] = issues[issue]
					// Actually changed this to just compare to the original array but leaving this just for the moment.
				//},    		

				//)
			)
		}

		//console.log(projectIssues)
		//console.log(projectIssuesObject)

	    this.state = {
	      projectIssues: projectIssues,
	      projectAllIssues: projectAllIssues,
	      projectState: projectState,
	      projectCounty: projectCounty
	    }		
	}

  	componentDidMount() {
      document.title = "Titlebox - Finished Title Walkthrough!"
	}

	render() {
	  	//var projectIssues = this.state.projectIssues;
	  	var projectAllIssues = this.state.projectAllIssues;
		var projectState = this.state.projectState;
		var projectCounty = this.state.projectCounty;

	    return (
			<div className="container">
			  	<div className="row">
				  	<nav className="col-md-3 d-md-block sidebar">
				  		<div className="row first-row">
					  		<small className="d-block pull-left state-box">
					  			{ projectState }
					  		</small>

					  		<small className="d-block pull-right county-box">
					  			{ projectCounty }
					  		</small>
				  		</div>

						<ul className="nav flex-column">
						  {Object.entries(projectAllIssues).map(([key, value]) =>
			              	<li className={ 'nav-link ' + key } key={ key }><a href={ '/documents/' + projectState + '/' + projectCounty + '/' + key.replace(' selected','') }>{ value }</a></li>
						  )}
			            </ul>
				  	</nav>

				  	<div className="content col-md-9">
				  		<div className="row">
					    	<div className="col-md-9">
					    		<h1>Walkthrough Finished!</h1>

					    		<p>Project complete, nice job clearing all the title issues. Head back to <a href="/">Home</a> to start your next project.</p>
					    	</div>
						</div>
				    </div>
			    </div>
			</div>
    	)
	}
}

export default withRouter(WalkthroughFinish);
