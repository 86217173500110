import React, { Component } from 'react';

if (!String.prototype.includes) {
  String.prototype.includes = function(search, start) {
    'use strict';

    if (search instanceof RegExp) {
      throw TypeError('first argument must not be a RegExp');
    } 
    if (start === undefined) { start = 0; }
    return this.indexOf(search, start) !== -1;
  };
}

// const history = createBrowserHistory();

var userSelectedContentOptions = '';
var projectIssuesObject = {}

function titleCase(str) {
   var splitStr = str.toLowerCase().split(' ')

   if (str.length > 4) {
     for (var i = 0; i < splitStr.length; i++) {
         // You do not need to check if i is larger than splitStr length, as your for does that for you
         // Assign it back to the array
         splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)     
     }

     splitStr = splitStr.join(' ')
    } else {
      splitStr = str.toUpperCase()
    }
   // Directly return the joined string
   return splitStr
}

export class Walkthrough extends Component {
  constructor(props) {
    super(props);

    // console.log(this.props)

  	var issues = this.props.masterissues

   	userSelectedContentOptions = localStorage.getItem('userSelectedContentOptions')

   	var projectIssues = ''
   	var projectAllIssues = ''
   	var projectState = ''
   	var projectCounty = ''

		if (userSelectedContentOptions) {
		    userSelectedContentOptions = JSON.parse(userSelectedContentOptions)

		    // This is not great, it creates a new object, checking the selected ones first, then adds to that new object after, then sets the state. 
		    // Has to be better but well, this does work.
		    issues.forEach(key => {
		    	//

		    	if (userSelectedContentOptions.issues.includes(key.value)) {
		    		//
		    		var objectName = key.value + ' selected'

		    		projectIssuesObject[objectName] = titleCase(key.value)
		    	} else {
		    		//
		    		projectIssuesObject[key.value] = titleCase(key.value)
		    	}

		    	return ''
		    }, 
			    projectIssues = userSelectedContentOptions.issues,
			    projectAllIssues = projectIssuesObject,
			    projectState = userSelectedContentOptions.state.toLowerCase(),
			    projectCounty = userSelectedContentOptions.county.toLowerCase()

				//userSelectedContentOptions.issues.forEach( function(issue) {
					//var objectName = issue + ' selected'
				
					//projectIssuesObject[objectName] = issues[issue]
					// Actually changed this to just compare to the original array but leaving this just for the moment.
				//},    		

				//)

			)
		} else if (issues) {
        issues.forEach(key => {
          projectIssuesObject[key.value] = titleCase(key.value)
          
          return ''
        }, 
          projectAllIssues = projectIssuesObject
        )
    }

    // console.log(projectAllIssues)

    projectState = localStorage.getItem('uwalAddress_state')
    projectCounty = localStorage.getItem('uwalAddress_county')
    var projectPotholes = localStorage.getItem('potholes')
    var uwalAddressNote = localStorage.getItem('uwalAddressNote')
    var resultsMeta = localStorage.getItem('resultsMeta')
    var resultsList = localStorage.getItem('resultsList')

    this.state = {
      pageContent: '',
      projectIssues: projectIssues,
      projectAllIssues: projectAllIssues,
      projectState: projectState,
      projectCounty: projectCounty,
      projectPotholes: projectPotholes,
      uwalAddressNote: uwalAddressNote,
      resultsMeta: JSON.parse(resultsMeta),
      resultsList: resultsList,
      savedFormattedAddress: '',
      value: '',
      uwalNames: [],
      loading: false,
      typingTimeout: 0 
    }

  }

  componentWillMount() {
    // var _thisComponent = this
  }

  componentDidMount() {
    // var _thisComponent = this

    document.title = "Titlebox - Start Title Walkthrough"
  }

  componentWillReceiveProps(props) {
    // console.log(props)

    this.setState({
      projectState: props.projectState,
      projectCounty: props.projectCounty,
      projectPotholes: props.projectPotholes,
      uwalAddressNote: props.uwalAddressNote,
      resultsMeta: props.resultsMeta,
      resultsList: props.resultsList
    })
  }

  goTo(route) {
    this.props.history.replace(`/${route}`)
  }

  logout() {
    this.props.auth.logout();
  }

  /*
  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      console.log(event)

      if (document.querySelectorAll('.pac-item').length) {
        document.querySelectorAll('.pac-item').forEach(function(e) {
          console.log(e)

          if (e.length) {
            console.log(e)

            e.click()
          }
        })
      }
    }
  }
  */

  render() {
  	var projectAllIssues = this.state.projectAllIssues
    var projectState = this.state.projectState
    var projectCounty = this.state.projectCounty
    var projectPotholes = this.state.projectPotholes
    var uwalAddressNote = this.state.uwalAddressNote

    // console.log('projectAllIssues')
    // console.log(projectAllIssues)
    if (projectState === 'FL') {
      projectAllIssues['HOW TO CLOSE IN STATE'] = 'How To Close In State'
    }

    // console.log('projectAllIssues')
    // console.log(projectAllIssues)

    // console.log(projectAllIssues['HOW TO CLOSE IN STATE'])
    

    // console.log(resultsList)

    // console.log('projectPotholes')
    // console.log(projectPotholes)

      if (projectPotholes) {
        projectPotholes = JSON.parse(projectPotholes)
      } else {
        projectPotholes = ''
      }
	    
      return (

      			<div className="">
              { uwalAddressNote ?
              <div className={"col-md-6 d-block mx-auto text-center rounded shadow uwalMessage " + uwalAddressNote }>
                { uwalAddressNote === 'found' ?
                  <>
                    <div className="col-md-5">
                      <img alt="Success Icon" src="https://thetitlebox.com/wp-content/uploads/2019/10/success.png" sizes="(max-width: 108px) 100vw, 108px" className="et-waypoint et_pb_animation_top et-animated"/> <span>Uh-Oh!</span> 
                    </div>

                    <div className="col-md-7">
                      <span>This property <strong>has been flagged</strong> and is on the UWAL!</span>
                    </div>
                  </>
                : null }
                { uwalAddressNote === 'notfound' ?
                  <>
                    <div className="col-md-5">
                      <img alt="Success Icon" src="https://thetitlebox.com/wp-content/uploads/2019/10/success.png" sizes="(max-width: 108px) 100vw, 108px" className="et-waypoint et_pb_animation_top et-animated"/> <span>Success!</span>
                    </div>

                    <div className="col-md-7">
                      <span>This property has <strong>NOT</strong> been flagged and is not on the UWAL!</span>
                    </div>
                  </>
                : null }
                
                <br className="clear"/>
              </div>
              : null }

      			  	<div className="row stage">
      				  	<div className="content col-md-12">
                    { projectState && projectCounty ?
                    <nav className="col-md-12 d-md-block row">
                      <div className="row">
                        <div className="col-md-8 p-6 title-clearance">
                          <h2 className="subTitle dark-grey text-center">Title Clearance</h2>                
                          <ul className="list-group d-flex flex-row flex-wrap">
                            {Object.entries(projectAllIssues).map(([key, value]) =>
                              <li className={ 'list-group-item w-45 list-group-item-action nav-link rounded shadow ' + key } key={ key }><a href={ '/documents/' + projectState + '/' + projectCounty + '/' + key.replace(' selected','') }><img alt="Union Icon" src="https://thetitlebox.com/wp-content/uploads/2019/10/Union-1.png" className="et-waypoint et_pb_animation_top et-animated"/> { value }</a></li>
                            )}
                          </ul>
                        </div>

                        { projectState && projectCounty && projectPotholes ?
                          <div className="col-md-4 p-6 potholes">
                            <h2 className="subTitle dark-grey text-center">Potholes</h2>                
                            <ul>
                                {Object.entries(projectPotholes).map(([key, value]) =>
                                  <li className={ 'nav-link ' + key } key={ key }><a className="" href={ '/documents/' + projectState + '/' + projectCounty + '/potholes/' + value['DocumentName'].replace('/','%2F') + '/' + value['DocumentName'].replace('/','%2F') }> { titleCase(value['DocumentName']) }</a></li>
                                )}
                            </ul>
                          </div>
                        : null }
                      </div>
                    </nav>
                    : null }

                    <br className="clear" />

      				  		<div className="row">
      					    	<div className="col-md-12 titleboxBreakdown">


                        <div id="how-it-work" className="et_pb_section et_pb_section_1 et_section_regular     how-it-work">
                          <div className="et_pb_row et_pb_row_4     row col-md-12 reorient">
                             <div className="et_pb_column et_pb_column_1_4 et_pb_column_4 et_pb_css_mix_blend_mode_passthrough     col-md-3">
                                <div className="col-md-12 text-center">
                                  <h3 className="dark-grey">How do I get started?</h3>

                                  <div className="et_pb_module et_pb_blurb et_pb_blurb_0 works-box et_pb_bg_layout_light et_pb_text_align_left et_pb_text_align_center-phone et_pb_blurb_position_left et_pb_blurb_position_top_phone et_had_animation     bg-white shadow p-3 rounded inner-box-height-80">

                                     <div className="et_pb_blurb_content">
                                        <div className=""><span className="et_pb_image_wrap"><img alt="Success Icon" src="https://thetitlebox.com/wp-content/uploads/2019/10/Union-1.png" className="et-waypoint et_pb_animation_top et-animated"/></span></div>
                                        <div className="et_pb_blurb_container">
                                           <h4 className="et_pb_module_header"><span>Start with an Address</span></h4>
                                           <div>
                                            Enter the address of the property you’re working on in the search bar at the top of this page. 
                                            <br/><br/>
                                            <ul>
                                              <li>Green or red will tell you if it’s on the UWAL.</li>
                                              <li>Check to see Potholes you could fall into on the road to settlement.</li>
                                              <li>Based on what items need clearance, choose a clearance category on the left to start clearing title.</li>
                                            </ul>
                                           </div>
                                        </div>
                                     </div>
                                  </div>

                                  <br/><br/>

                                  {/* <a href="" className="video_link p-2">Watch a Video!</a> */}
                                </div>
                             </div>{/* column_1 */}

                             <div className="et_pb_column et_pb_column_1_4 et_pb_column_4 et_pb_css_mix_blend_mode_passthrough     col-md-3">
                                <div className="col-md-12 text-center">
                                  <h3 className="dark-grey">How do I clear title with TitleBox?</h3>

                                  <div className="et_pb_module et_pb_blurb et_pb_blurb_0 works-box et_pb_bg_layout_light et_pb_text_align_left et_pb_text_align_center-phone et_pb_blurb_position_left et_pb_blurb_position_top_phone et_had_animation     bg-white shadow p-3 rounded inner-box-height-80">
                                     <div className="et_pb_blurb_content">
                                        <div className=""><span className="et_pb_image_wrap"><img alt="Success Icon" src="https://thetitlebox.com/wp-content/uploads/2019/10/Union-6.png" className="et-waypoint et_pb_animation_top et-animated"/></span></div>
                                        <div className="et_pb_blurb_container">
                                           <h4 className="et_pb_module_header"><span>Choose your problem</span></h4>
                                           <p>
                                            Above this box along the left, there are 10 categories for title clearance. Start with an item on your search that needs to be cleared and choose the appropriate category.
                                           </p>
                                        </div>
                                     </div>
                                  </div>

                                  <br/><br/>

                                  {/* <a href="" className="video_link p-2">Watch a Video!</a> */}
                                </div>
                             </div>{/* column_2 */}

                             <div className="et_pb_column et_pb_column_1_4 et_pb_column_4 et_pb_css_mix_blend_mode_passthrough     col-md-3">
                                <div className="col-md-12 text-center">
                                  <h3 className="dark-grey">Check for Potholes!</h3>

                                  <div className="et_pb_module et_pb_blurb et_pb_blurb_0 works-box et_pb_bg_layout_light et_pb_text_align_left et_pb_text_align_center-phone et_pb_blurb_position_left et_pb_blurb_position_top_phone et_had_animation     bg-white shadow p-3 rounded inner-box-height-80">

                                     <div className="et_pb_blurb_content">
                                        <div className=""><span className="et_pb_image_wrap"><img alt="Success Icon" src="https://thetitlebox.com/wp-content/uploads/2019/10/Union-6.png" className="et-waypoint et_pb_animation_top et-animated"/></span></div>
                                        <div className="et_pb_blurb_container">
                                           <h4 className="et_pb_module_header"><span>Close with comfort</span></h4>
                                           <p>
                                              Potholes are typically not on your title search, but important things you need to know before you settle and insure. Checking for Potholes is your way to close and insure transactions in areas that you may not be familiar with.
                                              <br/><br/>
                                              Potholes can be specific to a property’s state and county. Once you enter an address, the Potholes list appears along the right.
                                           </p>
                                        </div>
                                     </div>
                                  </div>

                                  <br/><br/>

                                  {/* <a href="" className="video_link p-2">Watch a Video!</a> */}
                                </div>
                             </div>{/* column_3 */}

                             <div className="et_pb_column et_pb_column_1_4 et_pb_column_4 et_pb_css_mix_blend_mode_passthrough     col-md-3">
                                <div className="col-md-12 text-center">
                                  <h3 className="dark-grey">What&apos;s the UWAL?</h3>

                                  <div className="et_pb_module et_pb_blurb et_pb_blurb_0 works-box et_pb_bg_layout_light et_pb_text_align_left et_pb_text_align_center-phone et_pb_blurb_position_left et_pb_blurb_position_top_phone et_had_animation     bg-white shadow p-3 rounded inner-box-height-80">
                                     <div className="et_pb_blurb_content">
                                        <div className=""><span className="et_pb_image_wrap"><img alt="Success Icon" src="https://thetitlebox.com/wp-content/uploads/2019/10/Union-5.png" className="et-waypoint et_pb_animation_top et-animated"/></span></div>
                                        <div className="et_pb_blurb_container">
                                           <h4 className="et_pb_module_header"><span>Underwriter Alert List</span></h4>
                                           <p>
                                              We’ve gathered thousands of addresses and names that have appeared in emailed alerts from title underwriters and made them searchable. The UWAL is updated daily and is the most comprehensive single source for high-risk properties and people in the title industry.
                                              <br/><br/>
                                              To search a name, toggle to Search by Name above the search bar.
                                           </p>
                                        </div>
                                     </div>
                                  </div>

                                  <br/><br/>

                                  {/* <a href="" className="video_link p-2">Watch a Video!</a> */}
                                </div>
                             </div>{/* column_4 */}

                        </div>{/* reorient */}
                      </div>{/* how-it-work */}

      					    </div>{/* titleboxBreakdown */}
      						</div>{/* row */}
      			    </div>{/* content */}
      			  </div>{/* row */}
      			</div>

      )
  }
}

export default Walkthrough;
