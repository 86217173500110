import React, { Component } from 'react';

const appUrl = "https://thetitlebox.com/wp-json/wp/v2/";
const getPost = `${appUrl}pages/?include[]=11423`;

export class LatestUpdates extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    /*
    const { renewSession } = this.props.auth;

    if (localStorage.getItem('isLoggedIn') === 'true') {
      renewSession();
    }
    */

    let postUrl = `${getPost}`;
    fetch(postUrl)
      .then(data => data.json())
      .then(data => {
          var postContent = data[0].content['rendered']
          // postContent = JSON.stringify(postContent)
          // postContent = postContent.toString()

          this.setState({
             updatesContent: postContent
          })

          document.querySelector('.contentContainer').innerHTML = postContent

          // console.log(postContent)
      })
  }

  render() {
    // const { isAuthenticated } = this.props.auth;
    // console.log( isAuthenticated() )

    return (
      <div className="col-md-8 p-6 d-block mx-auto contentContainer">
        <i className="fa fa-spinner fa-pulse"></i> Loading...
      </div>
    )
  }
}

export default LatestUpdates;
