import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

class Home extends Component {
  login() {
    this.props.auth.login();
  }

  logout() {
    this.props.auth.logout();
  }

  componentDidMount() {
    const { renewSession } = this.props.auth;

    if (localStorage.getItem('isLoggedIn') === 'true') {
      renewSession();
    }
  }

  render() {
    const { isAuthenticated } = this.props.auth;
    // console.log( isAuthenticated() )

    return (
      <div className="container">
        {
          isAuthenticated() && (
              <h4>
                You are logged in! &nbsp;
                  <Link
                    to=""
                    id="qsLogoutBtn"
                    className="btn-margin"
                    onClick={this.logout.bind(this)}
                  >Log Out</Link>
              </h4>
            )
        }
        {
          !isAuthenticated() && (
              <div className="col-md-6">
                <br />

                <h4>Welcome to Titlebox!</h4>
                <p>Click here to confirm your &nbsp;
                  <Link
                    to=""
                    style={{ cursor: 'pointer' }} 
                    onClick={this.login.bind(this)} 
                    className="text-primary">Log In</Link> 
                    &nbsp; to continue to the app.
                </p>
              </div>
            )
        }
      </div>
    );
  }
}

export default Home;
