import React, { Component } from 'react';
import { createBrowserHistory } from "history";
import JsxParser from 'react-jsx-parser'
import Select from 'react-select';
import axios from 'axios';
import { HashLink as Link } from 'react-router-hash-link';

import 'luminous-lightbox/dist/luminous.min';

const history = createBrowserHistory();

var userSelectedContentOptions = '';
//var projectIssues = '';

var projectIssuesObject = {}

function utf8_for_xml(inputStr) {
  return inputStr.replace(/[^\x20-\xFF\x85\xA0-\uD7FF\uE000-\uFDCF\uFDE0-\uFFFD]/gm, '');
}

var $domain = window.location.hostname
var API = '';

if (window.location.hostname === 'localhost') {
  API = 'http://' + window.location.hostname + ':3001/api';
} else {
  API = 'https://' + window.location.hostname + '/api';
}


/*
var getIssues = (input) => {
  return axios.post(API + `/issues`, {
  	data: {

  	}
  })
  .then((response) => {
    var issuesList = []

    //

    if (response.data.files) {
	    Object.values(response.data.files.recordsets[0]).forEach(function(trait) {
	      issuesList.push({ value: trait.ProblemType, label: trait.ProblemType });
	    });
	}
    //
    return issuesList;
  }).then((json) => {
    return { options: json };
  });
}
*/

function validateEmail(value) {
    // regex from http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value);
}

function titleCase(str) {
   var splitStr = str.toLowerCase().split(' ')

   if (str.length > 4) {
     for (var i = 0; i < splitStr.length; i++) {
         // You do not need to check if i is larger than splitStr length, as your for does that for you
         // Assign it back to the array
         splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)     
     }

     splitStr = splitStr.join(' ').replace('_', '')
    } else {
      splitStr = str.toUpperCase()
    }
   return splitStr
}

function potholesList(stateLocation, countyLocation, sid) {
  return axios.post(API + `/potholes`, {
      data: {
        sid: sid,
        stateName: stateLocation,
        countyName: countyLocation
      }
    })
    .then((response) => {
      // console.log(response)

      // return response.data.result.recordset
      return response.data.result
    })
    .catch(err => 
      console.log(err)
    )
}

var Next = (props) => {
	var split = props.nextDocument;

	if (split && split.includes('/')) {
		split = split.split('/');
		split = split[split.length-1]
	}

 	if (props.nextDocument) {
    	return <a href={ props.nextDocument } className='btn btn-primary float-right next-document'>{ 'Next: ' + split }</a>;
 	} else {
 		return '';
 	}
}

var pricingTable = (userLevel) => {
  var $userLevel = userLevel

  return axios.post(API + `/pricing`, {
    	method: 'HEAD',
        data: {
        	userLevel: $userLevel
        }
    })
  .then((response) => {

    return response
  }).then((json) => {
  	// console.log(json.data.result.recordsets[0][0])

    return json.data.result.recordsets[0][0]
  })
}

var Sidebar = (props) => {
	// console.log(props)
	// console.log(this)

 	if (props.sections) {
    	return ([
			<ul className="sidebar-sticky" key="sidebar">
			  {Object.entries(props.sections).map(([key, value]) =>
              	<li className={ 'nav-link ' + key } key={ key }><Link to={{
				  pathname: '/documents/' + props.state + '/' + props.county + '/' + props.issue + '/' + props.subissue + '/' + props.subissue + '/' + value.href.replace('?', ''),
				  search: '',
				  hash: '',
				  state: { fromDashboard: true }
				}}>{ value.name.replace(/<(?:.|\n)*?>/gm, '') }</Link></li>
			  )}
            </ul>
    	]);
 	} else {
 		return '';
 	}
}

class Report extends Component {
	constructor(props) {
	    super(props);

	    this.state = {
	      reportEmail: '',
	      pageTitle: this.props.pageTitle,
	      reportState: this.props.state,
	      reportCounty: this.props.county,
	      reportDocument: this.props.documentName,
	      reportSection: '',
	      reportMessage: '',
	      isSelectedSection: '',
	      isFilledreportMessage: '',
	      sendingReportStatus: this.props.sending,
	      sendingReportStatusMessage: '',
	  	};

	  	this.handleSubmit = this.handleSubmit.bind(this);
	  	this.hideShowReportCard = this.hideShowReportCard.bind(this);
	}

	hideShowReportCard(event) {
	  	this.props.hideMe();
	}

	handleSubmit(event) {
		event.preventDefault();
		event.persist();

		var _thisComponentReport = this

		var dataEmail = this.state.reportEmail;
		var dataSections = [];
		Object.values(this.state.reportSection).forEach( function(index) {
      		dataSections.push(index.value)
      	}) 
      	dataSections = dataSections.join(', ')
      	var dataPageTitle = this.state.pageTitle;
      	var dataMessage = this.state.reportMessage;
      	var reportState = this.state.reportState;
      	var reportCounty = this.state.reportCounty;
      	var reportDocument = this.state.reportDocument;

      	var AdminTextMessage = 'State: ' + reportState + ', County: ' + reportCounty + ', Page Title: ' + dataPageTitle + ', Document Name: ' + reportDocument + ' Section: ' + dataSections + ' Message: ' + dataMessage;
      	var AdminHTMLMessage = '<p>State: ' + reportState + ', County: ' + reportCounty + ', Page: ' + dataPageTitle + '</p><p>Section: ' + dataSections + '</p><p> Document Name: ' + reportDocument + '</p><p>Message: ' + dataMessage + '<p>';
      	var UserTextMessage = 'Thanks for reporting a correction or update to Titlebox! We\'ll review this quickly and update Titlebox with the right information.';
      	var UserHTMLMessage = 'Thanks for reporting a correction or update to Titlebox! We\'ll review this quickly and update Titlebox with the right information.';



	    _thisComponentReport.reportSection(this.state.reportSection)
	    _thisComponentReport.reportMessage(this.state.reportMessage)
	    _thisComponentReport.reportEmail(this.state.reportEmail)

        this.setState({
        	sendingReportStatus: 'sending',
        	sendingReportStatusMessage: 'Sending... <i className="fa fa-loading"></i>'
        })

        if (validateEmail(dataEmail) && dataEmail && dataMessage) {        
	        axios.post(API + `/report`, {
	            data: {
	                email: dataEmail,
	                AdminTextMessage: AdminTextMessage,
	                AdminHTMLMessage: AdminHTMLMessage,
	                UserTextMessage: UserTextMessage,
	                UserHTMLMessage: UserHTMLMessage
	            },
	            method: 'HEAD',
	                  headers: {
	                    'Content-Type': 'application/json',
	                  }
	            })
	            .then((response) => {
	                //
	                //return response;

			        this.setState({
			        	sendingReportStatus: 'success',
			        	sendingReportStatusMessage: 'Thank you! We\'ll take a look and follow up with you.'
			        })

			        setTimeout( function() {
			       		_thisComponentReport.hideShowReportCard()
			        }, 2000)
	            }).catch((error) => {
	                //
	            });
        } else {
        	if (!dataEmail) {
		        this.setState({
		        	sendingReportStatus: 'invalid',
		        	sendingReportStatusMessage: 'Please enter your email.'
		        })
		    } else {
		        this.setState({
		        	sendingReportStatus: 'invalid',
		        	sendingReportStatusMessage: 'Please enter all details.'
		        }) 	
		    }
        } 
	}

  	reportEmail = (reportEmail) => {

  		if (typeof reportEmail.target !== "undefined") {
			this.setState({ 
				reportEmail: reportEmail.target.value 
			});
		}

		if (reportEmail) {
			this.setState({ 
				sendingReportStatusMessage: ''
			})
		}
  	}

  	reportSection = (reportSection) => {


	    if (reportSection.length) {
			this.setState({ 
				reportSection: reportSection,
				isSelectedSection: ''
			},
	      		() => Object.values(this.state.reportSection).forEach( function(index) {
	      		})
	    	)

	    }
  	}

  	reportMessage = (reportMessage) => {


  		if (reportMessage) {
  			if (typeof reportMessage.target !== "undefined") {
				this.setState({
					reportMessage: reportMessage.target.value,
					isFilledreportMessage: ''
				})
			} else {
				this.setState({
					isFilledreportMessage: ''
				})				
			}
	    } else {
	      this.setState({ 
	        isFilledreportMessage: 'invalid'
	      })
	    }
  	}

	render () {
		var isSelectedSection = this.state.isSelectedSection 
		var isFilledreportMessage = this.state.isFilledreportMessage
		var sendingReportStatus = this.state.sendingReportStatus
		var sendingReportStatusMessage = this.state.sendingReportStatusMessage

		var sectionsArray = []
		this.props.sections.forEach( function(index) {
			sectionsArray.push(
				{ value: index.name, label: index.name }
			)
		})
		
	 	if (this.props.sections) {
	            return ([
		            <div className={ "row clear reportCard " + sendingReportStatus } key="report">
		                <div className="card col-md-12">
            				<div className="card-header">
		                    	<h5 className="card-title float-left">What needs to be updated?</h5>
                   				<button type="button" className="close" onClick={ this.hideShowReportCard } data-dismiss="modal">&times;</button>
                   			</div>
		                    
		                    <div className="card-body">		                        
		                        <form onSubmit={this.handleSubmit}>
		                            <div className="form-group">
		                                <label htmlFor="selectSections" className={ "selectSections " + isSelectedSection }>What section(s)?
								             <Select
								                key="reportSections"
								                name="reportSections"
								                value={this.state && this.state.reportSection}
								                multi
			                  					onChange={(...args) => this.reportSection(...args)}
								                placeholder="Choose sections"
								                options={sectionsArray}
								                //required
								            />

            								<small className={ 'selectSectionsMessage' }>Please let us know which section.</small>
								        </label>
		                            </div>

		                             <div className="form-group">
		                                <label htmlFor="textMessage" className={ "textMessage " + isFilledreportMessage }>What needs to be updated?
								             <textarea
								             	key="reportMessage"
								             	name="reportMessage"
								             	onChange={(...args) => this.reportMessage(...args)}
								             	className="form-control"
								             	//required
								             	></textarea>

            								<small className={ 'reportMessage' }>Please tell us what should be updated.</small>
							            </label>
		                            </div>

			                        <div className="">
			                            <label htmlFor="reportEmail" className="backupEmailaddress">Email address
				                            <input
				                                className={ 'form-control col-md-12 float-left ' + this.state.sending }
				                                placeholder="Enter your email..."
				                                name="reportEmail"
				                                key="reportEmail"
				                                type="text"
				                                onChange={this.reportEmail}
				                                //required
				                            />
			                            </label>

			                            <input
		                                    className="btn btn-primary col-md-2 float-right form-submit"
		                                    value="Send"
		                                    type="submit"
		                                />

            							<small className={ 'sendingReportStatusMessage' }><JsxParser jsx={ sendingReportStatusMessage }/></small>
		                            </div>
		                        </form>
		                    </div>
		                </div>
		            </div>
	            ]);
	 	} else {
	 		return '';
	 	}
	}
}

export class SingleDocument extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageContent: '',
      pageDropdown1: '',
      pageDropdown2: '',
      pageTitle: '',
      sections: '',
      projectIssues: '',
      projectState: '',
      projectCounty: '',
      showReportCard: false,
      showSignup: false,
      selectedOptionSection: '',
      sending: 'not'
  	}

  	var issues = this.props.masterissues

   	var projectIssues = ''
   	var projectAllIssues = ''
   	var projectState = ''
   	var projectCounty = ''

   	userSelectedContentOptions = localStorage.getItem('userSelectedContentOptions')

   	/*
   	getIssues().then(result => {
   		//
   	})
	*/


	if (userSelectedContentOptions) {
	    userSelectedContentOptions = JSON.parse(userSelectedContentOptions)

	    // This is not great, it creates a new object, checking the selected ones first, then adds to that new object after, then sets the state. 
	    // Has to be better but well, this does work.
	    issues.forEach(key => {
	    	//

	    	if (userSelectedContentOptions.issues.includes(key.value)) {
	    		//
	    		var objectName = key.value + ' selected'

	    		projectIssuesObject[objectName] = titleCase(key.value)
	    	} else {
	    		//
	    		projectIssuesObject[key.value] = titleCase(key.value)
	    	}

	    	return ''
	    }, 
		    projectIssues = userSelectedContentOptions.issues,
		    projectAllIssues = projectIssuesObject,
		    projectState = userSelectedContentOptions.state.toLowerCase(),
		    projectCounty = userSelectedContentOptions.county.toLowerCase()

		)
	} else if (issues) {
        issues.forEach(key => {
          projectIssuesObject[key.value] = titleCase(key.value)
          
          return ''
        }, 
          projectAllIssues = projectIssuesObject
        )
	}


    this.state = {
      pageContent: '<i className="fa fa-spinner fa-pulse"></i> Loading...',
      projectIssues: projectIssues,
      projectAllIssues: projectAllIssues,
      projectState: projectState,
      projectCounty: projectCounty,
      pageIssue: '',
      pageId: ''
    }

    this.hideShowReportCard = this.hideShowReportCard.bind(this);
    this.hideShowSignup = this.hideShowSignup.bind(this);
  }

  hideShowReportCard(event) {
  	this.setState(prevState => ({
		showReportCard: !prevState.showReportCard
	}))
  }

  hideShowSignup(event) {
  	this.setState(prevState => ({
		showSignup: !prevState.showSignup
	}))
  }

  handleChangeLink(event) {
  	var documentChosen = event.value



	history.push(documentChosen)

	var reloadHREF = window.location.href

	window.location.href = reloadHREF
  }


  componentDidMount() { 
    var _thisComponent = this;
    var sid = localStorage.getItem('sid')

    document.body.setAttribute('oncopy', 'return false');
    document.body.setAttribute('oncut', 'return false');
    document.addEventListener('contextmenu', function(ev) { ev.preventDefault(); return false; }, false);

    document.title = "Titlebox"

	// const LOCAL = '/documents/';
	var filesURL = API + '/documents/';
	var folders = '';

	var state = this.props.match.params.state;
	var county = this.props.match.params.county;
	var issue = this.props.match.params.issue;
	var subissue = this.props.match.params.subissue;
	var id = this.props.match.params.id;
	var section = this.props.match.params.section;

	this.setState({
		issue: issue,
		subissue: subissue,
		pageId: id,
		projectState: state,
		projectCounty: county
	})

	var $uwalAddressfromLocal = localStorage.getItem('uwalAddress')

	if ($uwalAddressfromLocal) {
		this.setState({
			savedFormattedAddress: localStorage.getItem('uwalAddress').replace(/"/gi, '')
		})

      var $potholes = localStorage.getItem('potholes')

      if (!$potholes) {
        potholesList(state, county)
        .then((response) => {
          this.setState({ 
            // projectPotholes: response
          })
        })
      } else {
	      this.setState({ 
	        projectPotholes: $potholes
	      })
      }
	}






	if (state)
		folders += state

	if (county)
		folders += '/' + county

	if (issue)
		folders += '/' + issue

	// console.log(subissue)

	if (subissue) {
		subissue = subissue.replace(/__/gi, " #").replace(/_/gi, " ").toUpperCase()
		// subissue = subissue 

		folders += '/' + subissue
	}

	filesURL += folders

	filesURL = filesURL.replace(/[^a-z0-9\/:/.]/gi, '_').toLowerCase()

    // document.title = "Titlebox - " + state.toUpperCase() + " " + titleCase(county) + " " + titleCase(issue)


    // console.log(filesURL)

	if (typeof id === 'undefined') {
		_thisComponent.setState({
			// pageTitle: titleCase(issue)
		})

		// console.log(filesURL)
		// console.log(issue)
		// console.log(subissue)

		axios.post(filesURL, {
                data: {
                	sid: sid,
                	issue: issue,
                    subissue: subissue            
                }
		    })
		    .then(function(response) {
		    	var links = '<ul className="nav flex-column">'
		    	//
		    	//response.data.files.recordset

		    	/*
		    	console.log('response')
		    	console.log(response)
		    	console.log(response.data.files.recordsets[0])
		    	console.log(response.data.files.recordsets[2])
				*/

		    	if (response.data.files && response.data.type === 'Judgments') {
	            	var documentNames = response.data.files;
		    	// if (response.data.files.recordset && response.data.files.recordset.length > 30 && !subissue) {
	            	// var documentNames = response.data.files.recordset;

	            	if (response.data.files.length > 1) {
		            	documentNames = documentNames.sort();
		            	documentNames.sort(function(a,b) {return (a.Subcategory > b.Subcategory) ? 1 : ((b.Subcategory > a.Subcategory) ? -1 : 0);} ); 
						var reformattedDropdown1 = documentNames.map(obj => { 
						   var rObj = {}
						   var issueEncodeSafe = encodeURI(issue).replace(/\//gi, 
						   	'%2F')
						   var issueSubcategory = encodeURI(obj.Subcategory).replace(/\//gi, 
						   	'%2F')

						   	var linkURLs = issueEncodeSafe + '/' + issueSubcategory + '/' + issueSubcategory

			            	// console.log(linkURLs)

						   rObj = { value: linkURLs, label: obj.Subcategory }
						   return rObj
						});

				    	_thisComponent.setState({
		                	pageContent: '',
		                	pageDropdown1: reformattedDropdown1
		            	});
					} else {
						// console.log(response.data.files)

				    	response.data.files.forEach( function(file) {
				    		links += '<li className="nav-link"><a href="./' + issue + '/' + file.Subcategory + '/' + file.Subcategory + '">' + titleCase(file.Subcategory) + '</a></li>'
				    	})
				    	links += '</ul>'

		            	_thisComponent.setState({
		                	pageContent: links
		            	});
					}

	            } else if (response.data.files.recordset && subissue && subissue.match(/(\d+)|^(LT-XX|DR-XX|lt_xx|dr_xx)/g)) {

	            	documentNames = response.data.files.recordset;

	            	documentNames = documentNames.sort();

	            	// console.log(documentNames)

					reformattedDropdown1 = documentNames.map(obj => { 
					   var rObj = {};
					   var linkURLs = obj.Subcategory
					   linkURLs = linkURLs.replace(/\s+$/, '').replace(/[^a-z0-9\/:]/gi, '_').toLowerCase() + '/' + obj.Subcategory

					   rObj = { value: linkURLs, label: obj.Subcategory }
					   return rObj;
					});

			    	_thisComponent.setState({
	                	pageContent: '',
	                	pageDropdown1: reformattedDropdown1	         
	                });
	            } else if (response.data.files.recordset && !subissue && response.data.files.recordset.length === 1) {
			    	var redirectTo = response.data.files.recordset[0].Subcategory + '/' + response.data.files.recordset[0].Subcategory
				    	redirectTo = `/documents/` + folders + '/' + redirectTo
			    	//
			    	// var reloadHREF = window.location.href

			    	_thisComponent.setState({
			    		redirectTo
			    	})
			    	response.data.files.recordset.forEach( function(file) {
			    		links += '<li className="nav-link"><a href="/documents/' + state + '/' + county + '/' + issue + '/' + file['Subcategory'] + '/' + file['Subcategory'] + '">' + file['Subcategory'] + '</a></li>'
			    	})
			    	links += '</ul>'
				    
	            	_thisComponent.setState({
	                	pageContent: links
	            	});

			    	//history.replace(`/documents/` + folders + '/' + redirectTo );
			      	//window.location.href = `/documents/` + folders + '/' + redirectTo;
	            } else if (response.data.files.recordset && !subissue) {
			    	response.data.files.recordset.forEach( function(file) {
			    		links += '<li className="nav-link"><a href="/documents/' + state + '/' + county + '/' + issue + '/' + file['Subcategory'] + '/' + file['Subcategory'] + '">' + file['Subcategory'] + '</a></li>'
			    	})
			    	links += '</ul>'
				    
	            	_thisComponent.setState({
	                	pageContent: links
	            	});
	            } else if (response.data.files && response.data.type === 'Subissue') {
	            // } else if (response.data.files.recordset && subissue) {
			    	// response.data.files.recordset.forEach( function(file) {



	            	var documentNames = response.data.files;
		    	// if (response.data.files.recordset && response.data.files.recordset.length > 30 && !subissue) {
	            	// var documentNames = response.data.files.recordset;

	            	if (response.data.files.length > 15) {
		            	documentNames = documentNames.sort();
		            	documentNames.sort(function(a,b) {return (a.Subcategory > b.Subcategory) ? 1 : ((b.Subcategory > a.Subcategory) ? -1 : 0);} ); 
						var reformattedDropdown1 = documentNames.map(obj => { 
						   var rObj = {}
						   var issueEncodeSafe = encodeURI(issue).replace(/\//gi, 
						   	'%2F')
						   var issueSubcategory = encodeURI(obj.Subcategory).replace(/\//gi, 
						   	'%2F')

						   	var linkURLs = issueEncodeSafe + '/' + issueSubcategory + '/' + issueSubcategory

			            	// console.log(linkURLs)

						   rObj = { value: linkURLs, label: obj.Subcategory }
						   return rObj
						});

				    	_thisComponent.setState({
		                	pageContent: '',
		                	pageDropdown1: reformattedDropdown1
		            	});
					} else {
						// console.log(response.data.files)

				    	response.data.files.forEach( function(file) {
			    		links += '<li className="nav-link"><a href="/documents/' + state + '/' + county + '/' + issue + '/' + file['Subcategory'] + '/' + file['Subcategory'] + '">' + file['Subcategory'] + '</a></li>'
				    	})
				    	links += '</ul>'

		            	_thisComponent.setState({
		                	pageContent: links
		            	});
					}
	            } else if (response.data.files) {
	            	// Since this is where the subissue listing happens, might be able to have it check for dropdown change.
			    	
	            	console.log(response.data.files)

			    	response.data.files.forEach( function(file) {

			    		file = file.replace('.docx', '')
			    		links += '<li className="nav-link"><a href="./' + file + '">' + titleCase(file) + '</a></li>'
			    	})
			    	links += '</ul>'
				    

	            	_thisComponent.setState({
	                	pageContent: links
	            	});
	            } else {
	            	_thisComponent.setState({
	                	pageContent: 'No files found!'
	            	});	    
	            }
			})

	} else {
	    var documentTitleSplit = id.split('-')
	    var documentTitleSplitLength = documentTitleSplit.length
	    var documentTitle = documentTitleSplit[documentTitleSplitLength-1]

		_thisComponent.setState({
			pageTitle: documentTitle,
			documentName: id
		})


				var finalURL = API + '/read-xml'

				subissue = subissue.replace('_', ' ')

			    _thisComponent.serverRequest = 
			      axios
			        .post(finalURL, {
			        	headers: { 'Cache-Control': 'no-cache' },
			        	responseType: 'arraybuffer',
						data: {
							ProblemName: issue,
							SubcategoryName: subissue,
							StateName: state,
							CountyName: county,
							checkLogin: 'loggedIn'
						}
			        })
			        .then(function(result) { 

			        	const parser = new DOMParser()

						var decoded = result.data
			          	var sectionMatch = []
			          	var HTMLtextFinal = ''

			          	// console.log(result)

			          	if (decoded && decoded.result && decoded.result.recordset && decoded.result.recordset[0]) {
			          		var repairXML = decoded.result.recordset[0].Content
			          		var repairingNeeded = repairXML.match(/<img (.*)><figcaption/g)
			          		// console.log(repairingNeeded)
			          		if (repairingNeeded)
			          			repairXML = repairXML.replace('><figcaption', '></img><figcaption')
				          	var decodedXML = utf8_for_xml(repairXML)
							decodedXML = parser.parseFromString(decodedXML, 'text/xml');
							decodedXML = decodedXML.querySelector('documentRoot')

							for (var i = 0; i < decodedXML.childNodes.length; i++) {
								if (decodedXML.childNodes[i].querySelector('title')) {

									
									var $currentTitle = decodedXML.childNodes[i].querySelector('title')
									var $currentTitleConformed = ''
									var $lastTitle = ''

									// console.log('$currentTitle')
									// console.log($currentTitle)

									if ($currentTitle.innerHTML) {
					                	$currentTitle 			= $currentTitle.innerHTML
					                	$currentTitleConformed 	= $currentTitle.replace('?','').replace(/ /gi, '').replace(/\s/gi, '').toLowerCase()
					                	if (i > 0 && decodedXML.childNodes[i-1].querySelector('title'))
					                		$lastTitle 				= decodedXML.childNodes[i-1].querySelector('title').innerHTML

									} else {
					                	$currentTitle = $currentTitle.textContent
					                	$currentTitleConformed 	= $currentTitle.replace('?','').replace(/ /gi, '').replace(/\s/gi, '').toLowerCase()
					                	if (i > 0 && decodedXML.childNodes[i-1].querySelector('title'))
					                		$lastTitle 				= decodedXML.childNodes[i-1].querySelector('title').textContent
									}

									// console.log('$currentTitle')
									// console.log($currentTitle)

									// console.log('$currentTitleConformed')
									// console.log($currentTitleConformed)

									if ($lastTitle !== $currentTitle) {
										sectionMatch.push(
											{ href: $currentTitle.replace(/ /gi, "").replace(/[:\? \s]/gi,'').toLowerCase(), name: $currentTitle }
										)
									}

									// console.log($currentTitle.replace(/ /gi, "").replace(/[:\? \s]/gi,'').toLowerCase() + '===' + section)
									// console.log(decodedXML.childNodes[0].querySelector('content'))

									if (section) {

										if ($currentTitle.replace(/ /gi, "").replace(/[:\? \s]/gi,'').toLowerCase() === section) {

											var sectionInUse = sectionMatch.findIndex(p => p.href === section)
											var innerHTMLupdated = ''

											if (decodedXML.childNodes[i].querySelector('content').innerHTML) {
												// console.log(new XMLSerializer().serializeToString(decodedXML.childNodes[i].querySelector('content')))
												innerHTMLupdated = decodedXML.childNodes[i].querySelector('content').innerHTML
											} else {
												// console.log(JSON.stringify(decodedXML.childNodes[i].querySelector('content')))
												innerHTMLupdated = new XMLSerializer().serializeToString(decodedXML.childNodes[i].querySelector('content'))
											}

											// if ($domain.indexOf('thetitlebox') > 0) {
												innerHTMLupdated = innerHTMLupdated.replace(/tciab.*?i\/(.*?)">/gi, "app.thetitlebox.com/documents/$1\"\>")
												innerHTMLupdated = innerHTMLupdated.replace(/api\/(.*?)">/gi, "documents/$1\"\>")
											// }

											innerHTMLupdated = innerHTMLupdated.replace(/(\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)/gi, "mailto:$1")
											innerHTMLupdated = innerHTMLupdated.replace(/mailto:mailto:/gi, "mailto:")
											innerHTMLupdated = innerHTMLupdated.replace(/href=("http)/gi, "target='_blank' href=$1")
											innerHTMLupdated = innerHTMLupdated.replace(/\{county\}/gi, county)
											innerHTMLupdated = innerHTMLupdated.replace(/%7Bcounty%7D/gi, county)

					        				HTMLtextFinal += innerHTMLupdated
										}
					        		} else if (decodedXML.childNodes[0].querySelector('content')) {
					        			// console.log('Go to first')

										if ($currentTitle.replace(/ /gi, "").replace(/[:\? \s]/gi,'').toLowerCase() === sectionMatch[0].href) {

											var sectionInUse = 0
											var innerHTMLupdated = ''

											if (decodedXML.childNodes[i].querySelector('content').innerHTML) {
												// console.log(new XMLSerializer().serializeToString(decodedXML.childNodes[i].querySelector('content')))
												innerHTMLupdated = decodedXML.childNodes[i].querySelector('content').innerHTML
											} else {
												// console.log(JSON.stringify(decodedXML.childNodes[i].querySelector('content')))
												innerHTMLupdated = new XMLSerializer().serializeToString(decodedXML.childNodes[i].querySelector('content'))
											}

											// if ($domain.indexOf('thetitlebox') > 0) {
												innerHTMLupdated = innerHTMLupdated.replace(/tciab.*?i\/(.*?)">/gi, "app.thetitlebox.com/documents/$1\"\>")
												innerHTMLupdated = innerHTMLupdated.replace(/api\/(.*?)">/gi, "documents/$1\"\>")
											// }
											innerHTMLupdated = innerHTMLupdated.replace(/(\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)/gi, "mailto:$1")
											innerHTMLupdated = innerHTMLupdated.replace(/mailto:mailto:/gi, "mailto:")
											innerHTMLupdated = innerHTMLupdated.replace(/href=("http)/gi, "target='_blank' href=$1")
											innerHTMLupdated = innerHTMLupdated.replace(/\{county\}/gi, county)
											innerHTMLupdated = innerHTMLupdated.replace(/%7Bcounty%7D/gi, county)

					        				HTMLtextFinal += innerHTMLupdated
										}
					        		}
				        		}
							}

							if (!sectionInUse)
								sectionInUse = 0

							

			        		if (sectionMatch) {
				        		var nextLink = sectionInUse+1
				        		if (sectionMatch[nextLink])
				        			nextLink = sectionMatch[nextLink].href.replace('?','')

				        		var previousLink = sectionInUse-1
				        		if (sectionMatch[previousLink])
				        			previousLink = sectionMatch[previousLink].href.replace('?','')

				        		var completeURL = '/documents/' + folders + '/' + id + '/'

				        		if (isNaN(nextLink)) {
				        			HTMLtextFinal += '<a href="' + completeURL + nextLink + '" className="col-md-2 pull-right">Next</a>' 
				        		}

				        		if (isNaN(previousLink)) {
				        			HTMLtextFinal += '<a href="' + completeURL + previousLink + '" className="col-md-2 pull-left">Previous</a>' 
				        		}
			        		}

						    const { isAuthenticated } = _thisComponent.props.auth;
							var definitelyLoggedin = isAuthenticated()

	              			var $documentStateId = String(result.data.result.recordsets[1][0].StateId)
	              			var $documentCountyId = String(result.data.result.recordsets[1][0].CountyId)


	              			var $holdingText = ''
	              			var $role = localStorage.getItem('role')
	              			var $subscriptions = localStorage.getItem('subscriptions')

	              			// console.log($role)

			        		// console.log('State + County')
			        		// console.log($documentStateId + '' + $documentCountyId)

			        		// console.log('subscriptions')
			        		// console.log($subscriptions)
			        		if ($subscriptions[0] && $subscriptions[0].length > 1 ) {
			        			$subscriptions = $subscriptions[0]
			        		}

			        		// console.log($subscriptions)

	              			if ($role && $subscriptions && $subscriptions !== 'undefined' && $role !== 'administrator') {

						       	pricingTable($subscriptions)
						        	.then((returnedRow) => {

						        		if (returnedRow) {
							        		var $returnedRowReference = String(returnedRow['ZoneReference'])
							        		var $returnedZone = String(returnedRow['Zone'])
							        		var $ZoneReference = $documentStateId + $documentCountyId
							        		$ZoneReference = parseInt($ZoneReference)
							        		$returnedRowReference = JSON.parse($returnedRowReference)

							        		// console.log('returnedRow')
							        		// console.log(returnedRow)

							        		// EXCEPTION Couldn't easily add multiple payment Zones to database


                                            if ($returnedRowReference.includes(parseInt($ZoneReference))) {
							        			$holdingText = HTMLtextFinal
							        		} else {
												$holdingText = '<p>You are currently subscribed to the ' + $returnedZone + ' zone and this county is in another zone. Check <a href="https://thetitlebox.com/plans-and-pricing/">Plans and Pricing.</a></p>'
							        		}
						        		} else {
											$holdingText = '<p>Couldn\'t connect to subscription server. Try reloading the page.</p>'
						        		}

						        		HTMLtextFinal = $holdingText

					                	_thisComponent.setState({
					                    	pageContent: HTMLtextFinal,
					                    	sections: sectionMatch
					                	})
						        	})
						        	.catch((err) => {
						        		console.log(err)
						        	})
						    } else if ($role === 'administrator') {
			                	_thisComponent.setState({
			                    	pageContent: HTMLtextFinal,
			                    	sections: sectionMatch
			                	})
						    } else {
								$holdingText = '<p>Subscription not found. Please check <a href="https://thetitlebox.com/plans-and-pricing/">Plans and Pricing.</a></p>'

			        			if (!$role || $role == 'undefined' || !$subscriptions) {
			        				setTimeout(function() {
			        					window.location.href = '//thetitlebox.com/plans-and-pricing/'
			        				}, 2000);
			        			}

				        		HTMLtextFinal = $holdingText

			                	_thisComponent.setState({
			                    	pageContent: HTMLtextFinal,
			                    	sections: sectionMatch
			                	})
						    }
						} else {
		                	_thisComponent.setState({
		                    	pageContent: 'Content coming soon!',
		                    	sections: ''
		                	})
						}
			        })

		/*
	    _thisComponent.serverRequest = 
	      axios
	        .post(finalURL, {
	        	headers: { 'Cache-Control': 'no-cache' },
	        	responseType: 'arraybuffer',
				data: {
					sid: sid,
					ProblemName: issue,
					SubcategoryName: subissue,
					StateName: state,
					CountyName: county,
					Section: section
				}
	        })
	        .then(function(result) { 

	        	// console.log('result')
	        	// console.log(result)

	        	
	        	if (result.data.result && result.data.result.HTMLtextFinal) {
	            	_thisComponent.setState({
	                	pageContent: result.data.result.HTMLtextFinal,
	                	sections: result.data.result.sectionMatch
	            	})
	        	} else if (result.data.result) {
	            	_thisComponent.setState({
	                	pageContent: result.data.result,
	                	sections: ''
	            	})
	        	} else {
	            	_thisComponent.setState({
	                	pageContent: 'Could not connect to server, try reloading or contact us if you continue to see this message.',
	                	sections: ''
	            	})
	        	}
	        	
	        })
	     */
	        
	}

	/*
    window.addEventListener("storage", function () {
    	window.location.reload()
    })
    */

    var currentURL = window.location.pathname

    // THIS IS CAUSING LOTS OF ISSUES
    this.unlisten = this.props.history.listen((location, action) => {
      
      // if (action === 'PUSH' || location.pathname.replace(/ /gi, '%20') !== currentURL)
      if (action === 'PUSH')
      	window.location.reload()
    });


    // THIS IS NEEDED FOR AUTH0!
    const { renewSession } = this.props.auth;

    if (localStorage.getItem('isLoggedIn') === 'true') {
      renewSession();
    }    
  }


  componentWillUnmount() {
      this.unlisten()
  }




  render() {
  	var projectAllIssues = this.state.projectAllIssues
    var projectState = this.state.projectState
    var projectCounty = this.state.projectCounty
    var projectPotholes = this.state.projectPotholes

    if (projectState === 'FL') {
      projectAllIssues['HOW TO CLOSE IN STATE'] = 'How To Close In State'
    }

    var nextDocument = this.state.nextDocument
    var pageContent = this.state.pageContent
    var pageDropdown1 = this.state.pageDropdown1
    var pageDropdown2 = this.state.pageDropdown2

      if (projectPotholes && projectPotholes !== undefined) {
        projectPotholes = JSON.parse(projectPotholes)
      }

    // var pageIssue = this.state.pageIssue;
    var listingClass = '';

    if (!this.state.sections) {
    	listingClass = 'singleDocument'
    } else {
    	listingClass = ''
    }

    /*
    if (this.state.pageTitle) {
    	var pageTitletoDisplay = this.state.pageTitle
    	pageTitletoDisplay = decodeURI(this.state.pageTitle.replace(/%2F/gi, '/'))
    }
    */

    return (
	  <div className="" key="overall">

	  	<div className={ 'row stage ' + this.state.showSignup } key="stage">
		  	<div className={ 'content ' + listingClass + ' col-md-9'} key="content">
		  		<div className="col-md-12">
	  				<h1>{ this.state.issue } 
	  				{ this.state.documentName ?
	  					<>
	  						<span className="title-arrow">&#9654;</span> 
	  						<span className="title-gray">{ this.state.documentName }</span>
	  					</>
	  				: null }
	  				</h1>

	  				{ this.state.sections ? <button className={ 'report ' + this.state.showReportCard } onClick={ this.hideShowReportCard }>Report an <span>Edit or Update</span></button> : null }
	  			
	  				<br className="clear" />
	  			</div>

				{ this.state.showReportCard ? <Report sending={ this.state.sending } hideMe={ this.hideShowReportCard } pageTitle={ this.state.pageTitle } documentName={ this.state.documentName } sections={ this.state.sections } state={ projectState } county={ projectCounty } /> : null }

		  		<div className={ pageDropdown1 || pageDropdown2 ? "row documentarea shadow dropdown" : "row documentarea shadow" }>
		  			{ this.state.documentName ?
			  			<nav className="col-md-3" key="sidebar">
				    		<Sidebar sections={ this.state.sections } issue={ this.state.issue } subissue={ this.state.subissue } state={ this.state.projectState } county={ this.state.projectCounty } />
				    	</nav>
			    	: null }

			    	<div className="col-md-9" key="mainContent">
			    		{ pageDropdown1 || pageDropdown2 ? 
				    		<div>
				    			{ pageDropdown1 ?
						        <Select 
						        	key="judgmentDocumentsName"
						            name="judgmentDocumentsName"
						            clearable={ false }
						            onChange={ this.handleChangeLink }
						            placeholder="Type or Choose by Document Name"
					                options={ pageDropdown1 }
						            className={ 'col-md-10 pull-left' }
						        />
						        :
						        <div/>
						    	}
					        </div>
				    	: 
				    		<JsxParser
				    			jsx={ pageContent }
				    		/>
				    	}

			    		<Next nextDocument={ nextDocument } />
			    	</div>
				</div>
		    </div>

		  	<nav className="col-md-3 d-md-block">
		  		<div className="col-md-12 sidebar shadow rounded">

	                <h2 className="subTitle">Title Clearance</h2>
					<ul className="nav flex-column titlecategories">
					  {Object.entries(projectAllIssues).map(([key, value]) =>
		              	<li className={ 'nav-link ' + key } key={ key }><a href={ '/documents/' + projectState + '/' + projectCounty + '/' + key.replace(' selected','').replace('?','') }>{ value }</a></li>
					  )}
		            </ul>

	              { projectState && projectCounty && projectPotholes ?
	              	<>
		              	<h2 className="subTitle">Potholes</h2>

		                <ul className="nav flex-column potholes">
		                    {Object.entries(projectPotholes).map(([key, value]) =>
		                      <li className={ 'nav-link ' + key } key={ key }><a href={ '/documents/' + projectState + '/' + projectCounty + '/potholes/' + value['DocumentName'].replace('/','%2F') + '/' + value['DocumentName'].replace('/','%2F') }>{ titleCase(value['DocumentName']) }</a></li>
		                    )}
		                </ul>
	                </>
	              : null }
              	</div>
		  	</nav>		  	
	    </div>
	  </div>
    )
  }
}

/*
{ pageDropdown2 && 1 !== 1 ?
<Select 
	key="judgmentDocumentsNumber"
    name="judgmentDocumentsNumber"
    clearable={ false }
    onChange={ this.handleChangeLink }
    placeholder="Type or Choose by Sample Number"
    options={ pageDropdown2 }
    className={ 'col-md-6 pull-right' }
/>
:
<div/>
}
*/

export default SingleDocument;
